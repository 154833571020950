import {DATE_PIPE_DEFAULT_OPTIONS, DatePipe, DatePipeConfig} from '@angular/common';
import {Inject, LOCALE_ID, Pipe} from '@angular/core';
import {DateTime, DurationLike} from 'luxon';
import {DatetimePicker} from "./datetime-local";

export type StartEnd ='start'|'end';

@Pipe({
    name: 'tilbyDate',
    standalone: true,
})
export class TilbyDatePipe extends DatePipe {

    static shopTimezoneName = "Europe/Rome";

    constructor(@Inject(DATE_PIPE_DEFAULT_OPTIONS) private datePipeConfig: DatePipeConfig,
                @Inject(LOCALE_ID) public localeId: string) {
        super(localeId);
    }

    override transform(value: any, pattern: string = 'yyyy-MM-dd HH:mm', timezoneName: string = TilbyDatePipe.shopTimezoneName, locale: string = this.localeId): any {
        const timezone = TilbyDatePipe.getGMTOffset(timezoneName, value);
        return super.transform(value, pattern, timezone, locale);
    }
    setPipeTimezone(timezoneName: string) {
        TilbyDatePipe.shopTimezoneName = timezoneName;
        this.datePipeConfig.timezone = TilbyDatePipe.getGMTOffset(timezoneName);
    }

    static getGMTOffset(timezoneName: string, date?: string): string {
        return date
            ? `${DateTime.fromJSDate(new Date(date)).setZone(timezoneName).offset}`.offsetToGMTNumber()
            : `${DateTime.local().setZone(timezoneName).offset}`.offsetToGMTNumber();
    }

    // @ts-ignore
    static date({date}?:{date?: string}|undefined): string;
    static date({outputFormat,date}:{outputFormat?: 'date', date?: Date }): Date;
    static date({outputFormat,date}:{outputFormat?: 'date', date?: string|Date }={}): string|Date|null{
        const my_date = !!date ? new Date(date) : new Date();
        if(!!outputFormat||typeof date==='object') {
            return DateTime.fromJSDate(my_date).toJSDate();
        }
        else {
            return DateTime.fromJSDate(my_date).toUTC().toISO();
        }
    }
    static toUtcDateMillis(localDate = this.date({})) {
        return (/^[0-9]*$/.test(localDate)) //verifica che la data che viene passata sia fatta da soli numeri (già in millisecondi)
            ? +localDate
            : DateTime.fromJSDate(new Date(localDate)).toUTC().toMillis();
    }
    static toUtcDateISO(localDate = this.date({})) {
        return DateTime.fromJSDate(new Date(localDate)).toUTC().toISO();
    }

    static shopDateStart({date,plus}:{date?: string | Date, plus?: DurationLike}={}): string|null{return TilbyDatePipe.shopDate({
        date,
        plus,
        setStartEnd:'start'
    });}
    static shopDateEnd({date,plus}:{date?: string | Date, plus?: DurationLike }={}) : string|null {return TilbyDatePipe.shopDate({
        date,
        plus,
        setStartEnd:'end'
    });}
    static shopDate({date,plus,setStartEnd,directViewInput}:{date?: string | Date, plus?: DurationLike, setStartEnd?: StartEnd,directViewInput?:boolean }={}):string|null {
        const my_date = !!date ? typeof date==='string'?new Date(date):date : new Date();
        let res = DateTime.fromJSDate(my_date,directViewInput?{zone: "UTC"}:undefined).setZone(TilbyDatePipe.shopTimezoneName, {keepLocalTime: true});
        if (plus) res = res.plus(plus||{});
        if (setStartEnd) res = setStartEnd === 'start'
            ? res.startOf('day')
            : res.endOf('day');
        return res.toUTC().toISO();
    }

    static setDatepickerTime({date, h, m}: DatetimePicker) {
        return DateTime.fromJSDate(new Date(date)).setZone("UTC",{keepLocalTime:true}).set({hour: +h, minute: +m}).toString();
    }
    /**
     * Method for Ag-grid date filter for shop timezone - from
     *
     * @param date
     * @returns shop date in string format (utc of timezone)
     */
    static shopDateGridFilterFrom(date: string) {
        return this.shopDate({date});
    }
    /**
     * Method for Ag-grid date filter for shop timezone - to
     *
     * @param date
     * @param plus
     * @returns shop date in string format (utc of timezone) plus x minutes
     */
    static shopDateGridFilterTo(date: string, plus: DurationLike = {day: 1}) {
        return this.shopDate({date, plus});
    }
    static compareDates(date1: string, date2: string) {
        const date_first = DateTime.fromISO(date1).toMillis();
        const date_second = DateTime.fromISO(date2).toMillis();
        if(date_first>date_second) return '>';
        if(date_first<date_second) return '<';
        return '=';
    }
    static durationBetweenDates(date: string, date2 = this.date()) {
        if(date) {
            const duration = this.toUtcDateMillis(date2) - this.toUtcDateMillis(date);
            const durationHours = Math.abs(duration) / 3600000;

            const hours = Math.floor(durationHours);
            const minutes = `${Math.floor((durationHours - hours) * 60)}`.padStart(2, '0');

            let durationString = duration < 0 ? '-' : '';
            durationString += hours > 0
                ?`${hours}:${minutes}'`
                :`${minutes}'`;

            return durationString;
        }
        else {
            return undefined;
        }
   }

}
