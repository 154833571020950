import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatProgressBarModule} from "@angular/material/progress-bar";

@Component({
  selector: 'tilby-dialog-progress-bar',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule],
  templateUrl: './tilby-dialog-progress-bar.component.html',
  styleUrls: ['./tilby-dialog-progress-bar.component.scss']
})
export class TilbyDialogProgressBarComponent {

}
