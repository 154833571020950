String.prototype.capitalize = function (this : string) {
    return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

String.prototype.toTitleCase = function (this : string) {
    return this.split(' ').map(w=>w.capitalize()).join(' ');
};

String.prototype.offsetToGMTNumber = function () {
    const my_this = this as any as number;
    const h = `${Math.abs(Math.floor(my_this / 60))}`.padStart(2, '0');
    const m = `${Math.abs(my_this % 60)}`.padStart(2, '0');
    return `${Math.sign(my_this) > 0 ? '+' : '-'}${h}:${m}`;
};


export {};

declare global {
    interface String {
        capitalize(): string;
        toTitleCase(): string;
        offsetToGMTNumber: () => string;
    }
}
