import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {
    distinctUntilChanged,
    Subject,
    takeUntil
} from "rxjs";
import {
    AsyncPipe,
    CommonModule,
    KeyValue,
    NgOptimizedImage
} from "@angular/common";
import {
    MatFormFieldAppearance,
    MatFormFieldModule
} from "@angular/material/form-field";
import { CustomFormControl } from "../utils";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
    FormsModule,
    ReactiveFormsModule
} from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { TranslateModule } from "@ngx-translate/core";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { AutocompleteFilteredPipe } from "../pipes";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { TilbyGesturesDirective } from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import { StartEnd } from "../models/tilby-magic-form.model";
import { TilbyDatetimePickerComponent } from "../components/tilby-datetime-picker";
import { TilbyTimePickerComponent } from "../components/tilby-time-picker";
import {
    TilbyCheckboxInputComponent,
    TilbyDatePickerInputComponent,
    TilbyDateRangePickerInputComponent,
    TilbyImageHandlerComponent,
    TilbyInputComponent,
    TilbyListOfItemsComponent,
    TilbyRadioInputComponent,
    TilbySelectInputComponent,
    TilbySlideToggleComponent,
    TilbyTextAreaComponent,
    TilbyTextInputAutocompleteComponent
} from "../components";

@Component({
    standalone: true,
    imports: [
        MatCheckboxModule,
        FormsModule,
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        TranslateModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatIconModule,
        MatAutocompleteModule,
        AutocompleteFilteredPipe,
        MatSelectModule,
        MatRadioModule,
        AsyncPipe,
        MatDividerModule,
        MatListModule,
        TilbyCurrencyPipe,
        TilbyGesturesDirective,
        TilbyDatetimePickerComponent,
        TilbyTimePickerComponent,
        NgOptimizedImage,
        TilbyInputComponent,
        TilbyTextAreaComponent,
        TilbyCheckboxInputComponent,
        TilbySelectInputComponent,
        TilbyRadioInputComponent,
        TilbyTextInputAutocompleteComponent,
        TilbyDatePickerInputComponent,
        TilbySlideToggleComponent,
        TilbyDateRangePickerInputComponent,
        TilbyListOfItemsComponent,
        TilbyImageHandlerComponent
    ],
    selector: 'tilby-form-control-element',
    templateUrl: './tilby-form-control-element.component.html',
    styleUrls: ['./tilby-form-control-element.component.scss'],
})
export class TilbyFormControlElementComponent implements OnInit,OnChanges,OnDestroy {

    @Input() el?: KeyValue<string, CustomFormControl<any>>;
    @Input() allWithCheckbox = false;
    get withCheckbox(){
        return (this.el && this.el.value.customProps.inputType.indexOf('-with-checkbox')>-1)||this.allWithCheckbox;
    }
    appearance: MatFormFieldAppearance = 'fill';
    hintAlign:StartEnd="end";
    onDestroy$=new Subject();
    dateEndControllerLabel?:string;
    isChecked=false;
    flex=1;
    
    constructor() {}

    ngOnInit() {
        this.listenRange();
        this.toggleControl();
    }

    listenRange(){
        if(this.el?.value.customProps.inputType === 'dateRangeStart'){
            this.dateEndControllerLabel=this.el.key.replace('Start','').concat('End')
            this.el.value?.statusChanges.pipe(takeUntil(this.onDestroy$),distinctUntilChanged()).subscribe(x=>{
                // @ts-ignore
                const dateEndController = this.el?.value.parent?.controls[this.dateEndControllerLabel!]
                if(x.toLowerCase()==='disabled'){
                    dateEndController?.disable();
                    dateEndController.reset(dateEndController?.customProps.inputInitialValue);
                } else {
                    dateEndController?.enable();
                }

            })
        }
    }

    toggleControl(){
        this.isChecked = !this.el?.value.pristine;
        const {disabled,customProps:{readonly=undefined,inputType=undefined,inputChoices=[]}={}} = this.el?.value||{};
        const notHidden = inputType !== 'hidden' && !this.withCheckbox;
        const listEmpty = inputType == 'listOfItems' && inputChoices.length<=0 ;
        setTimeout(()=>this.el?.value?.[(notHidden||this.isChecked)&&!listEmpty&&!disabled&&!readonly? 'enable':'disable']());
    }

    ngOnChanges(simpleChanges: SimpleChanges){
        if(simpleChanges['allWithCheckbox'] && !simpleChanges['allWithCheckbox'].firstChange && (simpleChanges['allWithCheckbox'].currentValue!== simpleChanges['allWithCheckbox'].previousValue)){
            this.toggleControl()
        }
    }

    setDisable(checked:boolean) {
        this.el?.value?.[checked ? 'enable' : 'disable']();
        !checked && this.el?.value.reset(this.el?.value.customProps?.inputInitialValue);
    }

    ngOnDestroy(){
        this.onDestroy$.next(null);
        this.onDestroy$.complete();
    }
}
