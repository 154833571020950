import { KeyValue } from "@angular/common";
import {ICustomFormGroupProps} from "../../models";

export class CustomFormGroupProps implements ICustomFormGroupProps {
    positionIndex?:number;
    label= '';
    class?:string;
    classLabel?:string;
    matErrorClass?:string;
    errors:KeyValue<string, string>[]=[]; //tilbyErrors Cannot Be as Default in This
}

export class CustomFormArrayProps extends CustomFormGroupProps{
}
