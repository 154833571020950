import {Component, inject, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {ControlContainer, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {CustomFormControl, CustomFormControlProps, CustomFormGroup, InputTypeMode, StartEnd} from "../../public_api";
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'tilby-input',
  standalone: true,
  imports: [CommonModule, MatInputModule, MatIconModule, ReactiveFormsModule, MatButtonModule, TranslateModule],
  templateUrl: './tilby-input.component.html',
  styleUrls: ['./tilby-input.component.scss'],
})
export class TilbyInputComponent {
  private parentControl = inject(ControlContainer);

  @Input({required:true}) formControlLabel!: string | number;
  @Input() inputmode: InputTypeMode = 'text';
  @Input() appearance: MatFormFieldAppearance='fill';
  @Input() hintAlign: StartEnd ='end';
  @Input() flex: number = 1;

  protected parentForm!:CustomFormGroup<any>;
  protected control!:CustomFormControl;
  protected customProps: CustomFormControlProps<any,any> = new CustomFormControlProps();

  ngOnInit(){
    this.parentForm=this.parentControl.control as CustomFormGroup<any>;
    this.control=this.parentForm?.controls[this.formControlLabel] as CustomFormControl;
    this.customProps=this.control.customProps;
  }
}
