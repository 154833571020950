import { mobileCheck } from '@tilby/tilby-ui-lib/utilities';
import {NonNullableObjectField} from "@tilby/tilby-ui-lib/models";
import { MatDialogConfig } from '@angular/material/dialog';

export type NonNullableConfigData<T> = NonNullableObjectField<MatDialogConfig<T>, 'data'>;

export interface Dimensions{
    width?:string;
    height?:string;
    minWidth?:string;
    maxWidth?:string;
    maxHeight?:string;
}

export class BaseDialogService {

    width?:string=mobileCheck()? '100vw':undefined;
    height?:string=mobileCheck()? '100vh':undefined;

    private log(...args:any[]) {
        // console.log('BASE',...args);
    }

    /**
     * Switches between mobile and desktop dimensions.
     *
     * @param {Dimensions} [dim] - The dimensions object containing width, height, minWidth, maxWidth, and maxHeight properties.
     * @returns {Object} - The updated dimensions object with width, height, minWidth, maxWidth, and maxHeight properties.
     */
    protected switchMobileDesktopDimensions(dim?:Dimensions, options?:{fullScreenForMobile?: boolean}){
        const {width=dim?.width,height=dim?.height}=(options?.fullScreenForMobile?this:{});
        return {
            ...(!!width&&{width}),
            ...(dim?.minWidth&&{minWidth:dim.minWidth}),
            ...(!!height&&{height}),
            maxWidth:dim?.maxWidth||'100vw',
            maxHeight:dim?.maxHeight||'100vh'
        };
    }
}
