import { NgClass, NgForOf, NgIf, NgStyle } from "@angular/common";
import { Component, Input, inject } from "@angular/core";
import { ControlContainer, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { StartEnd } from "../../models";
import { CustomFormControl, CustomFormControlProps, CustomFormGroup } from "../../utils";
import { MatDatepickerModule } from "@angular/material/datepicker";


@Component({
    selector: 'tilby-date-picker-input',
    templateUrl: './tilby-date-picker-input.component.html',
    styleUrls: ['./tilby-date-picker-input.component.scss'],
    standalone: true,
    imports: [ MatDatepickerModule, MatInputModule, MatIconModule, ReactiveFormsModule, MatButtonModule, NgStyle, NgForOf, NgIf, NgClass, TranslateModule],
})
export class TilbyDatePickerInputComponent {
    private parentControl = inject(ControlContainer);

    @Input({required:true}) formControlLabel!: string | number;
    @Input() appearance: MatFormFieldAppearance = 'fill';
    @Input() hintAlign: StartEnd ='end';
    @Input() flex: number = 1;

    protected parentForm!:CustomFormGroup<any>;
    protected control!:CustomFormControl;
    protected customProps: CustomFormControlProps<any,any> = new CustomFormControlProps();

    ngOnInit(){
        this.parentForm=this.parentControl.control as CustomFormGroup<any>;
        this.control=this.parentForm?.controls[this.formControlLabel] as CustomFormControl;
        this.customProps=this.control.customProps;
    }
}