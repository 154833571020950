import { KeyValue } from "@angular/common";
import {EventEmitter} from "@angular/core";

export type TouchClickEvent = TouchEvent| MouseEvent;
export type Gesture = 'swipeLeft' | 'swipeRight' | 'longPress' | 'scrollDown' | 'scrollUp';
export type GestureKeyValue = KeyValue<Gesture,number>;
export type OutputGesture = {
    [prop in Gesture]: EventEmitter<number>
};

export const X_MIN_VARIATION = 20;
export const Y_MIN_VARIATION = 20;
export const XY_MIN_SCROLL_VARIATION = 10;
export const TIME_MIN_VARIATION = 600;
export const TIME_MIN_VARIATION_BEFORE_TOUCHMOVE_EMIT = 200; //don't put less for touch, if you need to put less on Click create a variable
