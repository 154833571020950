import {
  Component,
  HostBinding,
  inject,
  Input,
  QueryList,
  ViewChildren
} from '@angular/core';
import {
  MatListItem,
  MatListModule
} from "@angular/material/list";
import { CommonModule } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { TilbyTrackVisibilityDirective } from "@tilby/tilby-ui-lib/directives/tilby-track-visibility";
import {
  TilbyDatePipe,
  TimePicker
} from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { ViewportRuler } from "@angular/cdk/overlay";
import { mobileCheck } from "@tilby/tilby-ui-lib/utilities";
import { FormControl } from "@angular/forms";
import { HostControlDirective } from "../../../directives/host-controller.directive";

@Component({
  selector: 'tilby-time',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatListModule, TilbyTrackVisibilityDirective],
  templateUrl: './tilby-time.component.html',
  styleUrls: ['./tilby-time.component.scss'],
  hostDirectives:[HostControlDirective]
})
export class TilbyTimeComponent {
  private hcd = inject(HostControlDirective);

  private readonly tilbyDatePipe = inject(TilbyDatePipe);
  private readonly viewportRuler = inject(ViewportRuler);
  protected isDisabled = false;

  @HostBinding('style.--right') @Input() right='';
  @ViewChildren('hours') hoursItem: QueryList<MatListItem> | undefined;
  @ViewChildren('minutes') minutesItem: QueryList<MatListItem> | undefined;

  control!:FormControl<TimePicker>;

  get timePicker(){
    return this.control?.value || this.setTimePicker(this.getShopDateTime(TilbyDatePipe.date(),'HH:mm')||'00:00');
  }

  timePickerAtOpen=this.timePicker;

  HOURS = [...Array(24).keys()].map((x) => `${x}`.padStart(2, '0'));
  MINUTES = [...Array(60).keys()].map((x) => `${x}`.padStart(2, '0'));
  NOW = this.setTimePicker(this.getShopDateTime(TilbyDatePipe.date(),'HH:mm')||'00:00');
  isMobile=false;
  stockVisible: { h: string[], m: string[] } = {h: [], m: []};

  private setTimePicker(time: string): TimePicker {
    return {
      h: time.split(':')[0] || '00',
      m: time.split(':')[1] || '00',
    };
  }
  private getShopDateTime(date: string, format: string): string | null {
    return this.tilbyDatePipe.transform(date, format) || '';
  }

  constructor() {
    this.isMobile = mobileCheck()||this.viewportRuler.getViewportSize().width<=600;
  }

  ngOnInit() {
    ({control:this.control} = this.hcd);
    this.timePickerAtOpen=this.timePicker;
  }

  ngAfterViewInit(){
    if(!this.right) this.scrollIntoView();
  }

  protected visibleTimeMobileAutoCheck($event: boolean, value: string, type: 'h' | 'm') {
    if(!this.isMobile||!this.right) return;
    if (value) {
      $event
          ? this.stockVisible[type].push(value)
          : (this.stockVisible[type]=this.stockVisible[type].filter((x) => x !== value));
      this.control.setValue({...this.control.value,[type]:[...this.stockVisible[type]].pop()||this.timePicker[type]})
    }
  }
  public scrollIntoView() {
    const center: ScrollIntoViewOptions = {
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    };
    this.timePickerAtOpen = this.timePicker;
    setTimeout(() => {
      this.hoursItem?.find((i) => i._elementRef.nativeElement.classList.contains('selected'))?._elementRef.nativeElement.scrollIntoView(center);
      this.minutesItem?.find((i) => i._elementRef.nativeElement.classList.contains('selected'))?._elementRef.nativeElement.scrollIntoView(center);
    });
  }


  setTimeInView(value: string, type: 'h' | 'm'){
    this.control.setValue({...this.control.value,[type]:value});
  }
}
