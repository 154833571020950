import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {ITALY} from "./utils";

export const FORM_GROUP_VALIDATORS ={
/**
 *
 * @param controlNamesToBeRequired Array of control names that become required when controlNamesToEvaluate is triggered
 * @param operator '&&'|'||' determines if the controlNamesToEvaluate array is in an AND or OR condition for the required check
 * @param controlNamesToEvaluate Array of control that triggers the required check
 */
    requiredValidator: (controlNamesToBeRequired: string[],operator:'&&'|'||',controlNamesToEvaluate=controlNamesToBeRequired): any | ValidatorFn => {
        return (fg: FormGroup): ValidationErrors|null => {
            const isRequired = controlNamesToEvaluate[operator=='||'?'some':'every'](controlName=> !!fg.controls[controlName]?.value);
            const hasRequired = fg.controls[controlNamesToBeRequired[0]].hasValidator(Validators.required);
            if(isRequired && !hasRequired){
                controlNamesToBeRequired.forEach(controlName=> {
                    // console.log('ADDED VALIDATORS',controlName);
                    fg.controls[controlName]?.addValidators(Validators.required);
                    fg.controls[controlName]?.updateValueAndValidity({onlySelf: true});
                });
            } else if(!isRequired && hasRequired) {
                controlNamesToBeRequired.forEach(controlName => {
                    // console.log('REMOVED VALIDATORS', controlName);
                    fg.controls[controlName]?.removeValidators(Validators.required);
                    fg.controls[controlName]?.updateValueAndValidity({onlySelf: true});
                });
            }
            return null;
        }
    },
}

export const FORM_CONTROL_VALIDATORS = {

    cannotContainSpace: (control: AbstractControl): ValidationErrors | null => {  
        return control.value && control.value.indexOf(' ') >= 0 ? { cannotContainSpace: true } as ValidationErrors : null;  
    },

    cannotContainCharacters: (control: AbstractControl) : ValidationErrors | null => {
        return control.value && control.value.match(/^[0-9]+$/) === null ? { cannotContainCharacters: true } as ValidationErrors : null;
    }
}

export interface ProvValidator {
    provMinLength: number;
    provMaxLength: number;
    provMaxLengthArray?: number[];
}
export const myProvFormValidator: any = <T extends ProvValidator>(provMaxLength: T,val?: number): ValidationErrors | null => {
    return (fg: FormGroup): ValidationErrors | null => {
        const prop_country = Object.keys(fg.controls).find(prop => prop.indexOf('country') > -1);
        const prop_prov = Object.keys(fg.controls).find(prop => prop.indexOf('prov') > -1);
        const country = fg.controls[`${prop_country}`]?.value
        const prov = fg.controls[`${prop_prov}`]?.value
        val !== undefined && provMaxLength.provMaxLengthArray
            ? provMaxLength.provMaxLengthArray[val] = country === ITALY ? 2 : 255
            : provMaxLength.provMaxLength = country === ITALY ? 2 : 255;
        const x = country === ITALY
            ? {
                ...(prov?.length > 2 && {maxLength: true}),
                ...(!(new RegExp(`^[a-zA-Z]{2}$`).test(prov)) && !!prov && {pattern: true})
            }
            : {
                ...(!(new RegExp(`^[a-zA-Z]{2,}$`).test(prov)) && !!prov && {pattern: true})
            };
        fg.controls[`${prop_prov}`]?.setErrors(!!Object.keys(x).length ? x : null);
        return null;
    }
};

export const MyCustomerNameValidator: any = (fg: FormGroup): ValidationErrors|null => {
    const prop_first_name = Object.keys(fg.controls).find(prop => prop.indexOf('first_name') > -1);
    const prop_last_name = Object.keys(fg.controls).find(prop => prop.indexOf('last_name') > -1);
    const prop_company_name = Object.keys(fg.controls).find(prop => prop.indexOf('company_name') > -1);
    const first_name = fg.controls[`${prop_first_name}`]?.value;
    const last_name = fg.controls[`${prop_last_name}`]?.value;
    const company_name = fg.controls[`${prop_company_name}`]?.value;
    fg.controls[`${prop_company_name}`]?.setErrors(null);
    fg.controls[`${prop_first_name}`]?.setErrors(null);
    fg.controls[`${prop_last_name}`]?.setErrors(null);

    if (!!company_name || (!!first_name && !!last_name)) {
        return null;
    }
    else if (!!first_name && !last_name) {
        fg.controls[`${prop_last_name}`]?.setErrors({required: true});
    }
    else if (!first_name && !!last_name) {
        fg.controls[`${prop_first_name}`]?.setErrors({required: true});
    }
    else if (!company_name && !first_name && !last_name) {
        fg.controls[`${prop_company_name}`]?.setErrors({required: true});
        fg.controls[`${prop_first_name}`]?.setErrors({required: true});
        fg.controls[`${prop_last_name}`]?.setErrors({required: true});
    }
    return null;
};

export const RequireMatch = <T extends { code: string, name: string }>(controlList: T[]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors|null => {
        const selection: any = control.value || '';
        if (controlList.map(({name}) => name).indexOf(selection) > -1 || selection === '') {
            return null;
        }
        return {matchRequired: true};
    }
}
export const ValidateTaxCode = (validatorFunction: (arg: string) => boolean): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const selection: any = control.value || '';
        if (selection === '' || validatorFunction(selection)) {
            return null;
        }
        return {pattern: true};
    }
}

export const  noWhitespaceValidator = (control: AbstractControl) => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'required': true };
}

// DA TESTARE
export const generalRepeatValidator = (controlName1:string, controlName2:string, errorName:string): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const control1 = control.get(controlName1);
        const control2 =control.get(controlName2);
        const value1 = control1?.value;
        const value2= control2?.value;
        if (value1 && !value2) {
            const errors = control1?.errors || {};
            control1?.setErrors({ ...errors, [`${errorName}1`]: true });
            control2?.setValidators([Validators.required]);
            return { [`${errorName}1`]: true };
        }
        else if (!value1 && value2) {
            const errors = control2?.errors || {};
            control2?.setErrors({ ...errors, [`${errorName}2`]: true });
            control1?.setValidators([Validators.required]);
            return { [`${errorName}2`]: true };
        }
        else if (value1 && value2 && value1 !== value2) {
            const errors = control2?.errors || {};
            control2?.setErrors({ ...errors, [`${errorName}`]: true });
            const errors2 = control1?.errors || {};
            control1?.setErrors({ ...errors2, [`${errorName}`]: true });
            return { [`${errorName}`]: true };
        }
        return null;
    }
}

export const ValidateTableInBounds = (minSize: number): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors|null => {
        const selection: any = control.value || '';
        if(minSize > selection * 80){
            return {tableInBounds: true};
        }
        return null;
    }
}
