import {Component, EventEmitter, HostBinding, Input, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {TranslateModule} from "@ngx-translate/core";
import {TilbyDatePipe} from "@tilby/tilby-ui-lib/pipes/tilby-date";
import {TilbyCurrencyPipe} from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import {TilbyGesturesDirective} from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import {Sales} from "tilby-models";
import {GeneralInputButton} from "@tilby/tilby-ui-lib/models";

@Component({
  selector: 'tilby-active-sale-header-info',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatMenuModule, MatIconModule, TilbyDatePipe, TilbyCurrencyPipe, TranslateModule, TilbyGesturesDirective, TilbyCurrencyPipe],
  templateUrl: './tilby-active-sale-header-info.component.html',
  styleUrl: './tilby-active-sale-header-info.component.scss',
  host: {
    class: 'tw-block tw-min-h-24 tw-border-solid tw-border tw-flex tw-flex-col tw-justify-between tw-gap-y-1 tw-border-gray-200 tw-rounded-lg'
  }
})
export class TilbyActiveSaleHeaderInfoComponent {
  @Input() @HostBinding('class.tw-pb-2') sale: Sales | undefined;
  @Input() disableActions=false;
  @Input() accessibility=false;
  @Input() isOrderPrinterPermitted=true;
  @Input() showPreBill=true;
  @Input() showSaleFinalAmount=false;
  @Input() showDeselectSale=false;
  @Input() isPaymentsState=false;
  @Input() contextMenu : GeneralInputButton[] = [];
  @Output() addCustomerActionEvent = new EventEmitter<void>();
  @Output() removeCustomerActionEvent = new EventEmitter<void>();
  @Output() preBillActionEvent = new EventEmitter<void>();
  @Output() saleChangeNameActionEvent = new EventEmitter<void>();
  @Output() openOrderPrinterSelectActionEvent = new EventEmitter<void>();
  @Output() selectSaleActionEvent = new EventEmitter<void>();
  @Output() deselectSaleActionEvent = new EventEmitter<void>();
  customerLabel : string | undefined;
  formattedDateCreatedAt : string | undefined;
  formattedDateDeliverAt : string | undefined;

  addCustomerAction(){
    this.addCustomerActionEvent.emit();
  }
  removeCustomerAction(){
    if(this.customerLabel){
      this.removeCustomerActionEvent.emit();
    }
  }

  preBill() {
    this.preBillActionEvent.emit();
  }

  saleChangeName() {
    this.saleChangeNameActionEvent.emit();
  }

  openOrderPrinterSelect() {
    this.openOrderPrinterSelectActionEvent.emit();
  }

  selectSaleAction(){
    this.selectSaleActionEvent.emit();
  }

  deselectSaleAction(){
    this.deselectSaleActionEvent.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['sale'] && (changes['sale'].currentValue || changes['sale'].currentValue === undefined) ) {
      this.customerLabel = this.getCustomerLabel(this.sale) || undefined;
      this.formattedDateCreatedAt = this.isToday(this.sale?.created_at)|| undefined;
      this.formattedDateDeliverAt = this.isToday(this.sale?.deliver_at)|| undefined;
    }
  }

  private getCustomerLabel(sale?: Sales) : string | undefined {
    if(!sale) {
      return undefined;
    } else if (sale.sale_customer?.first_name && sale.sale_customer?.last_name) {
      return `${sale.sale_customer.first_name} ${sale.sale_customer.last_name}`;
    } else if (sale.sale_customer?.company_name) {
      return sale.sale_customer.company_name;
    } else if (sale.customer_tax_code) {
      return sale.customer_tax_code;
    }
  }

  private isToday(date: string | Date | undefined) {
    if (date){
      const dateSale = TilbyDatePipe.shopDate({date: date});
      const dateNow = TilbyDatePipe.shopDate();
      return dateSale?.substring(0,10) === dateNow?.substring(0,10) ? 'HH:mm' : 'HH:mm dd/MM';
    }
  }

  protected getExternalId(externalId: string) {
    if(externalId.length > 10) {
      return externalId.substring(0,10)+'...';
    }
    return externalId;
  }
}
