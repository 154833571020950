import { CommonModule } from "@angular/common";
import { Component, Input, inject } from "@angular/core";
import { ControlContainer, ReactiveFormsModule } from "@angular/forms";
import { CustomFormControl, CustomFormControlProps, CustomFormGroup } from "../../utils";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { StartEnd } from "../../models";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {TranslateModule} from "@ngx-translate/core";


@Component({
    selector: 'tilby-checkbox-input',
    templateUrl: './tilby-checkbox-input.component.html',
    styleUrls: ['./tilby-checkbox-input.component.scss'],
    standalone: true,
    imports: [CommonModule, MatCheckboxModule, ReactiveFormsModule, TranslateModule],
})
export class TilbyCheckboxInputComponent {
    @Input() flex: number = 1;
    @Input({required:true}) formControlLabel!: string | number;
    @Input() appearance: MatFormFieldAppearance = 'fill';
    @Input() hintAlign: StartEnd ='end';

    private parentControl = inject(ControlContainer);
    protected parentForm!:CustomFormGroup<any>;
    protected control!:CustomFormControl;
    protected customProps: CustomFormControlProps<any,any> = new CustomFormControlProps();

    ngOnInit(){
        this.parentForm=this.parentControl.control as CustomFormGroup<any>;
        this.control=this.parentForm?.controls[this.formControlLabel] as CustomFormControl;
        this.customProps=this.control.customProps;
    }
}
