export const defaultErrors = [
    { key: 'min', value: 'NG_MESSAGES.NUMBER_NOT_VALID' },
    { key: 'max', value: 'NG_MESSAGES.NUMBER_NOT_VALID' },
    { key: 'required', value: 'NG_MESSAGES.REQUIRED_FIELD' },
    { key: 'email', value: 'NG_MESSAGES.INVALID_VALUE' },
    { key: 'minlength', value: 'NG_MESSAGES.NAME_TOO_SHORT' },
    { key: 'maxlength', value: 'NG_MESSAGES.NAME_TOO_LONG' },
    { key: 'pattern', value: 'NG_MESSAGES.INVALID_VALUE', },
    { key: 'matchRequired', value: 'NG_MESSAGES.INVALID_VALUE', },
];
export const customErrors = []

export const tilbyErrors = [...defaultErrors,...customErrors];
