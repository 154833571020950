import {CountryCode, countryCodes} from "./constants";
import {Observable, Observer} from "rxjs";
import {DestroyRef, inject} from "@angular/core";
import {
    CustomCallbackParams,
    CustomDeleteBehaviorForArray,
    DeleteBehaviorValue,
    KeysToFindDifferences,
    Options
} from "@tilby/tilby-ui-lib/models";
import { Categories } from "tilby-models";

export const ITALY = 'Italy';

export class MathUtils {
    static round(num : number,decimals=2){
        return Math.round((num+Number.EPSILON)*(10**decimals))/(10**decimals);
    }
}
const userAgent = navigator.userAgent.toLowerCase();
export const mobileCheck = () => {
    let check = false;
    ((a) => {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};
export const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

export const countryCodesShort: (Pick<CountryCode,'name'>&{code:string})[] = Object.entries(countryCodes).map(([code,{name}]) => ({code, name}));

export const countryToCode = (k: string,v:string|null) => k.indexOf('country')>-1 && (v=countryCodesShort.find(c=>c.name===v)?.code||null);
export const codeToCountry = (k:string,v:string|null) => k.indexOf('country')>-1 && (v=countryCodesShort.find(c=>c.code===v)?.name||null);

export const sleep = ( ms:number ) => new Promise( resolve => setTimeout( resolve, ms ) );

export function subscribeInComponent<T>(obs$:Observable<T>,observerOrNext:Partial<Observer<T>>|((value:T)=>void)){
    const subscription = obs$.subscribe(observerOrNext);
    inject(DestroyRef).onDestroy(()=>subscription.unsubscribe());
    return subscription;
}

/**
 * Groups the elements of an array based on a given function.
 *
 * @param {T[]} arr - The array to be grouped.
 * @param {(element: T) => any} f - The function used to determine the grouping.
 * @return {Record<string, T[]>} An object containing the grouped elements.
 */
export function groupBy<T>(arr: T[], f: (element: T) => any): Record<string, T[]> {
    const groups: Record<string, T[]> = {};

    for (const item of arr) {
        const group = `${f(item)}`;

        if (!(group in groups)) {
            groups[group] = [];
        }

        groups[group]?.push(item);
    }

    return groups;
}

/**
 * A function that creates a dictionary of elements from an array, using a specified key function.
 *
 * @param {T[]} arr - The array from which to create the dictionary.
 * @param {(element: T) => any} f - The key function used to generate the keys for the dictionary.
 * @param {Object} options - The optional configuration options.
 * @param {boolean} options.firstMatchOnly - If true, when there are duplicates of the same key, only the first element will be included in the dictionary instead of the last one.
 * @return {Record<string, T>} - A dictionary where the keys are generated using the key function and the values are the corresponding elements from the array.
 */
export function keyBy<T>(arr: T[], f: (element: T) => any, options?: { firstMatchOnly?: boolean }): Record<string, T> {
    const dictionary: Record<string, T> = {};

    for (const item of arr) {
        const key = `${f(item)}`;

        if (!options?.firstMatchOnly || !dictionary[key]) {
            dictionary[key] = item;
        }
    }

    return dictionary;
}

export const createDelta = <T extends object>(pristineItem:T, editedItem:T, options: Options<T> ,objectByPathMap:Map<string,any>,nullForJson:boolean, keyLevelLabel: keyof T | 'main' = 'main',path:string='main') => {
    pristineItem=structuredClone(pristineItem);
    editedItem=structuredClone(editedItem);

    const { keysToFindDifferences = {} as KeysToFindDifferences<T>, keysToKeep = {everywhere:[],specificPath:[]}, keysToRemoveStartsWith = {everywhere:[],specificPath:[]}, customDeleteBehaviorForArray = {} as CustomDeleteBehaviorForArray<T>} = options || {};

    const nestedEntryRemover = (parent: any, key: string) => {
        const entryRemover = (parent: any, k: string) => {
            if (keysToRemoveStartsWith.everywhere?.find(keyToRemoveStartsWith => k.startsWith(keyToRemoveStartsWith))||keysToRemoveStartsWith.specificPath?.find(keyToRemoveStartsWith => `${path}.${k}`.startsWith(keyToRemoveStartsWith))) {
                delete parent[k];
                return true;
            }
            return false;
        }
        const value = parent[key];
        const keyRemoved = entryRemover(parent, key);
        if (!keyRemoved) {
            if (typeof value == 'object' && value != null) {
                for (let k in value) {
                    nestedEntryRemover(value, k);
                }
            }
        }

    }

    const calculateCustomBehaviourArrays = ({primaryKey,customDiffCallback}: DeleteBehaviorValue, key: keyof T, customBehaviorKeys: string[]) => {
        // @ts-ignore
        if(!!editedItem?.[key]?.[0]?.[primaryKey] || !!pristineItem?.[key]?.[0]?.[primaryKey]){
            const isParentDeleted = !Object.keys(editedItem).length;
            return customDiffCallback((pristineItem?.[key] as any[])||[],(editedItem?.[key] as any[])||[],isParentDeleted);
        } else {
            console.warn('ArrayPrimaryKey???',key,primaryKey);
        }
    }

    const deleteKeyIfItIsAnEmptyObject = (key:keyof T) => {
        const isDeltaKeyAnEmptyObject = /*!delta[key] || */(typeof delta[key] == 'object' && delta[key] !== null && ((Array.isArray(delta[key]) && !delta[key]?.length) || !Object.keys(delta[key] || {})?.length));
        if (isDeltaKeyAnEmptyObject) { delete delta[key]; }
    }

    const deleteKeyIfItIsAnEmptyArrayObject = (key:keyof T,i:number) => {
        const isDeltaKeyAnEmptyArrayObject = !delta[key][i] || (typeof delta[key][i] == 'object' && ((Array.isArray(delta[key][i]) && !delta[key]?.[i]?.length) || !Object.keys(delta[key][i] || {})?.length));
        if (isDeltaKeyAnEmptyArrayObject) { delete delta[key][i]; }
    }

    const setNestedDelta = (isObjectNotEmpty: boolean, key: keyof T) => {
        if (isObjectNotEmpty) {
            const pristineItemKey=pristineItem[key]||{} as any;
            const editedItemKey=editedItem[key]||{} as any;

            delta[key] = createDelta(pristineItemKey, editedItemKey, options,objectByPathMap, nullForJson,key,`${path}.${key.toString()}`);
            deleteKeyIfItIsAnEmptyObject(key)
        }
    }

    const setNestedDeltaForArray = (isObjectNotEmpty: boolean, key: string,arrays:{arrayBehaviorValue:DeleteBehaviorValue<keyof T>,concatChangedAnRemovedElements:T[],editedByKey:Record<keyof T,T[]>,pristineByKey:Record<keyof T,T[]>}) =>{
        const {arrayBehaviorValue:{primaryKey,keepAllKeys},concatChangedAnRemovedElements,pristineByKey,editedByKey} = arrays;
        if (isObjectNotEmpty && typeof concatChangedAnRemovedElements?.[0] == 'object' && primaryKey in concatChangedAnRemovedElements?.[0]) {
            delta[key] ||= [];
            // console.log('deltaKey',key,delta[key],concatChangedAnRemovedElements)
            delta[key]=concatChangedAnRemovedElements.map((changedElement,i)=>{
                // @ts-ignore
                const createDeltaRes=createDelta(pristineByKey[changedElement[primaryKey]]?.[0]||{}, editedByKey[changedElement[primaryKey]]?.[0]||{}, {...options},objectByPathMap, nullForJson, key,`${path}.${key}[${i}]`);
                // console.log('changedElement',`${path}.${key}[${i}]`,key,{changedElement,pristine:pristineByKey[changedElement[primaryKey]],createDeltaRes,merge:{...createDeltaRes,...changedElement}});
                if(!(Object.keys(createDeltaRes).length||keepAllKeys)) return undefined;
                objectByPathMap.set(`${path}.${key}[${i}]`,{...createDeltaRes,...(keepAllKeys&&{...changedElement})});
                return createDeltaRes;
                // deleteKeyIfItIsAnEmptyObject(key);
            }).filter(el=>!!el);
            // console.log('concatChangedAnRemovedElements', {key, primaryKey, deltaKey:delta[key], concatChangedAnRemovedElements});
        } else {
            console.warn(`${primaryKey} is ArrayPrimaryKey of ${key} in ${path}???`, {key:concatChangedAnRemovedElements?.[0]});
        }
    }

    const deltaKeyCalculate = (key:keyof T) => Object.entries(keysToFindDifferences).find(([k,v])=>k.indexOf(keyLevelLabel as string) > -1 && v.indexOf(key as string)>-1)
        ? (Number(editedItem[key])||0) - (Number(pristineItem[key]) || 0)
        : editedItem[key] //Se c'è mette editedItem[key], altrimenti ...
                ?? ((((keysToKeep.everywhere?.indexOf(key as string) || 0) > -1) || ((keysToKeep.specificPath?.indexOf(`${path}.${key as string}`) || 0) > -1))
                    ?pristineItem[key]
                    :nullForJson && Object.keys(editedItem).length
                        ?null
                        :undefined
                ); //Very important if the item is deleted is to set pristineItem[key] for the always presents keys

    if (typeof pristineItem !== 'object' || typeof editedItem !== 'object') {
        throw new Error('Input items must be objects');
    }

    const delta = (Array.isArray(editedItem) || Array.isArray(pristineItem)) ? [] : {} as any;
    const outerJoinOfAllKeysArray = [...new Set<keyof T&string>([...Object.keys(editedItem), ...Object.keys(pristineItem)]as any)];

    for (const key of outerJoinOfAllKeysArray) {
        const entryChanged = pristineItem[key] != editedItem[key];
        const isObject = (typeof editedItem[key] == 'object' && typeof pristineItem[key] == 'object');
        // @ts-ignore
        const isObjectNotEmpty = editedItem[key]?.length || Object.keys(editedItem[key] || {})?.length;
        const customBehaviorKeys = Object.keys(customDeleteBehaviorForArray || {});
        const customBehaviorValue = Object.values(customDeleteBehaviorForArray || {}) as DeleteBehaviorValue<keyof T>[];
        const isObjectAndNotCustomBehaviorArray = isObject && !customBehaviorKeys.find(customKeys => customKeys == key);

        const entryChangedOrDeltaQuantity = entryChanged || Object.entries(keysToFindDifferences).find(([k,v])=>k.indexOf(keyLevelLabel as string) > -1 && v.indexOf(key)>-1);


        if (((keysToKeep.everywhere?.indexOf(key) || 0) > -1) || ((keysToKeep.specificPath?.indexOf(`${path}.${key}`) || 0) > -1)) {
            delta[key] = deltaKeyCalculate(key);
        } else if (entryChangedOrDeltaQuantity) {
            // @ts-ignore
            const isArrayKey=(editedItem[key]?.length || pristineItem[key]?.length) && (typeof editedItem[key] == 'object' || typeof pristineItem[key] == 'object');
            const arrayBehaviorValue = customBehaviorValue.find(({ arrayName }) => arrayName == key);
            if (isObjectAndNotCustomBehaviorArray) { // KEY IS OBJECT OR ARRAY NOT CUSTOM
                setNestedDelta(isObjectNotEmpty, key);
            }
            else if (arrayBehaviorValue && isArrayKey) { // KEY IS A CUSTOM ARRAY TYPE NOT EMPTY
                const {concatChangedAnRemovedElements=[],editedByKey={},pristineByKey={}}=calculateCustomBehaviourArrays(arrayBehaviorValue, key, customBehaviorKeys)||{};
                // @ts-ignore
                const isArrayItemAnObjectNotEmpty = editedItem[key]?.[0]?.length || Object.keys(editedItem[key]?.[0] || {})?.length || pristineItem[key]?.[0]?.length || Object.keys(pristineItem[key]?.[0] || {})?.length;
                setNestedDeltaForArray(isArrayItemAnObjectNotEmpty,key,{arrayBehaviorValue,concatChangedAnRemovedElements,editedByKey,pristineByKey});
            }
            else{ // KEY IS A PRIMARY TYPE || EMPTY ARRAY
                delta[key] = deltaKeyCalculate(key);
                deleteKeyIfItIsAnEmptyObject(key);
            }
        } else {
            // if(path=="main.sale_items[0]")console.log('(NOT_CHANGED) -', keyLevelLabel.toUpperCase(), key);
        }

        nestedEntryRemover(delta, key);
        // console.log('DELTA for',keyLevelLabel,key,delta,{objectByPathMap})
    }
    // console.log('DELTA',keyLevelLabel,delta,{objectByPathMap})

    //  SET ALL INNER CUSTOM ARRAYS
    if(keyLevelLabel=='main'){
        Array.from(objectByPathMap.keys()).reverse().forEach(key=>{
            const innerPath=key.replace('main.','').split(/[.\[\]]+/).filter(Boolean);
            const setValueAtPath = (obj:any, path:string[], value:any) => path.reduce((acc, key, index, arr) => value && (acc[key] = (index === arr.length - 1) ? value : (acc[key] || {})), obj);
            setValueAtPath(delta,innerPath,objectByPathMap.get(key));
            // console.log('SET ALL INNER ARRAYS',objectByPathMap.get(key))
        });
    }
    return delta;
}

export const createDeltaCustomCallback =<T extends Record<string,any>>({primaryKey,removedElementsProps,transform}:CustomCallbackParams<T>)=> (pristineElements:T[],editedElements:T[],isParentDeleted:boolean)=>{
    if(transform){
        editedElements = editedElements.map(e=>transform(e));
    }
    // @ts-ignore
    const pristineByKey = groupBy(structuredClone(pristineElements),(item)=>item[primaryKey]);
    // @ts-ignore
    const editedByKey = groupBy(structuredClone(editedElements),(item)=>item[primaryKey]);

    const removedElements = Object.values(pristineByKey).filter(([elementPristineKey])=>!Object.keys(editedByKey).find(elementEdited=>elementEdited==elementPristineKey?.[primaryKey as keyof T])).flatMap(a=>({...a[0],...removedElementsProps}));
    const changedElements = Object.values(editedByKey).filter(([elementEdited])=>!Object.values(pristineByKey).find(([elementPristineKey])=>Object.entries(elementPristineKey||{}).every(([k,v])=>v==elementEdited?.[k as keyof T]))).flatMap(a=>a);
    const concatChangedAnRemovedElements =isParentDeleted?[]: [...removedElements,...changedElements];
    // console.log(primaryKey,concatChangedAnRemovedElements)
    return {
        pristineByKey,
        editedByKey,
        removedElements,
        changedElements,
        concatChangedAnRemovedElements
    };
};

export const customSort = (a: Categories, b: Categories) => {
    const hasIndexA = 'index' in a;
    const hasIndexB = 'index' in b;

    if (hasIndexA && hasIndexB) {
        if (a.index !== b.index) {
            return a.index! - b.index!;
        } else {
            return a.name.localeCompare(b.name);
        }
    }

    if (hasIndexA) return -1;
    if (hasIndexB) return 1;

    return a.name.localeCompare(b.name);
}
