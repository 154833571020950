import {Component, inject, Input} from '@angular/core';
import {NgClass, NgForOf, NgIf, NgStyle} from '@angular/common';
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {CustomFormControl, CustomFormControlProps, CustomFormGroup, StartEnd} from "../../public_api";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {ControlContainer, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'tilby-tilby-text-area',
    standalone: true,
    imports: [NgIf, MatInputModule, MatIconModule, ReactiveFormsModule, MatButtonModule,NgClass, NgStyle, NgForOf, TranslateModule],
    templateUrl: './tilby-text-area.component.html',
    styleUrls: ['./tilby-text-area.component.scss']
})
export class TilbyTextAreaComponent {
    private parentControl = inject(ControlContainer);

    @Input({required:true}) formControlLabel!: string | number;
    @Input() appearance: MatFormFieldAppearance='fill';
    @Input() hintAlign: StartEnd ='end';
    @Input() flex: number = 1;

    protected parentForm!:CustomFormGroup<any>;
    protected control!:CustomFormControl;
    protected customProps: CustomFormControlProps<any,any> = new CustomFormControlProps();

    ngOnInit(){
        this.parentForm=this.parentControl.control as CustomFormGroup<any>;
        this.control=this.parentForm?.controls[this.formControlLabel] as CustomFormControl;
        this.customProps=this.control.customProps;
    }
}
