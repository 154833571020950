import {
    Component,
    forwardRef,
    inject,
    Input
} from '@angular/core';
import { CommonModule } from "@angular/common";
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule
} from "@angular/forms";

import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

import { MatListModule } from "@angular/material/list";

import { TranslateModule } from "@ngx-translate/core";

import {
    DatetimePicker,
    TilbyDatePipe,
    TimePicker
} from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { TilbyTrackVisibilityDirective } from "@tilby/tilby-ui-lib/directives/tilby-track-visibility";
import { MatNativeDateModule } from '@angular/material/core';
import { TilbyTimeComponent } from "../tilby-time-picker";
import { CustomFormControl } from '../../utils';

@Component({
    standalone: true,
    selector: 'tilby-datetime-picker',
    templateUrl: './tilby-datetime-picker.component.html',
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCardModule,
        MatNativeDateModule,
        MatListModule,
        TranslateModule,
        TilbyTrackVisibilityDirective,
        TilbyDatePipe,
        MatDatepickerModule,
        ReactiveFormsModule,
        TilbyTimeComponent
    ],
    styleUrls: ['./tilby-datetime-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TilbyDatetimePickerComponent),
            multi: true,
        }
    ]
})
export class TilbyDatetimePickerComponent implements ControlValueAccessor {
    private readonly tilbyDatePipe = inject(TilbyDatePipe);

    protected isDisabled = false;
    private _datetimePicker = this.setDatetimePicker(TilbyDatePipe.date());
    get datetimePicker(){
        return this._datetimePicker;
    }
    set datetimePicker(dateTime:DatetimePicker){
        this._datetimePicker=dateTime;
        this.timePickerControl.setValue(dateTime);
    }

    datetimePickerAtOpen=this.datetimePicker;
    timePickerControl = new CustomFormControl<TimePicker>(this.datetimePicker);

    @Input() label: string = 'Choose date and time';
   /* @Input() set date(date: string) {
        this.datetimePicker = this.setDatetimePicker(date);
    }*/

    private setDatetimePicker(date: string): DatetimePicker {
        return {
            date: date || TilbyDatePipe.date(),
            h: this.getShopDateTime(date, 'HH') || '00',
            m: this.getShopDateTime(date, 'mm') || '00',
        };
    }
    private getShopDateTime(date: string, format: string): string | null {
        return this.tilbyDatePipe.transform(date, format) || '';
    }

    setDatePicker({value: newDatePicked}: {value:string}) {
        const stringDate = TilbyDatePipe.setDatepickerTime({...this.timePickerControl.value, date: newDatePicked});
        this.setShopTimezone(stringDate);
        this._onChange(this.datetimePicker.date);
    }

    setShopTimezone(stringDate: string) {
        this.datetimePicker.date = TilbyDatePipe.shopDate({date: stringDate, directViewInput: true}) || '';
    }

//START - ControlValueAccessor
    public writeValue(date: string): void {
        if(!!date){
            this.datetimePicker = this.setDatetimePicker(date);
        }
    }

    private _onChange = (_value: string | null): void => undefined;
    public registerOnChange(fn: (value: string | null) => void): void {
        this._onChange = (value: string | null) => {
            fn(value);
        };
    }

    public onTouched = (): void => undefined;
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
// END - ControlValueAccessor

    updatePicker($event: any) {
        if ($event.target?.value) {
            this.datetimePicker = this.setDatetimePicker($event.target.value);
        }
    }

    onDatePickerCancel() {
        this.datetimePicker=this.datetimePickerAtOpen;
    }

    onClosed() {
        this.datetimePickerAtOpen=this.datetimePicker;
    }
}

