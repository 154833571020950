type Currency = {
    code: string,
    symbol_native: string,
    decimal_digits: number,
    symbol_first?: boolean
};

export const currencies : { [key:string]: Currency } = {
    "AED": {
        "code": "AED",
        "symbol_native": "د.إ.\u200F",
        "decimal_digits": 2
    },
    "AFN": {
        "code": "AFN",
        "symbol_native": "؋",
        "decimal_digits": 0
    },
    "ALL": {
        "code": "ALL",
        "symbol_native": "Lekë",
        "decimal_digits": 0
    },
    "AMD": {
        "code": "AMD",
        "symbol_native": "֏",
        "decimal_digits": 0
    },
    "ANG": {
        "code": "ANG",
        "symbol_native": "NAf.",
        "decimal_digits": 2
    },
    "AOA": {
        "code": "AOA",
        "symbol_native": "Kz",
        "decimal_digits": 2
    },
    "ARS": {
        "code": "ARS",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "AUD": {
        "code": "AUD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "AWG": {
        "code": "AWG",
        "symbol_native": "Afl.",
        "decimal_digits": 2
    },
    "AZN": {
        "code": "AZN",
        "symbol_native": "\u20BC",
        "decimal_digits": 2
    },
    "BAM": {
        "code": "BAM",
        "symbol_native": "КМ",
        "decimal_digits": 2
    },
    "BBD": {
        "code": "BBD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "BDT": {
        "code": "BDT",
        "symbol_native": "৳",
        "decimal_digits": 2
    },
    "BGN": {
        "code": "BGN",
        "symbol_native": "лв.",
        "decimal_digits": 2
    },
    "BHD": {
        "code": "BHD",
        "symbol_native": "د.ب.\u200F",
        "decimal_digits": 3
    },
    "BIF": {
        "code": "BIF",
        "symbol_native": "FBu",
        "decimal_digits": 0
    },
    "BMD": {
        "code": "BMD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "BND": {
        "code": "BND",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "BOB": {
        "code": "BOB",
        "symbol_native": "Bs",
        "decimal_digits": 2
    },
    "BOV": {
        "code": "BOV",
        "symbol_native": "BOV",
        "decimal_digits": 2
    },
    "BRL": {
        "code": "BRL",
        "symbol_native": "R$",
        "decimal_digits": 2
    },
    "BSD": {
        "code": "BSD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "BTN": {
        "code": "BTN",
        "symbol_native": "Nu.",
        "decimal_digits": 2
    },
    "BWP": {
        "code": "BWP",
        "symbol_native": "P",
        "decimal_digits": 2
    },
    "BYN": {
        "code": "BYN",
        "symbol_native": "Br",
        "decimal_digits": 2
    },
    "BZD": {
        "code": "BZD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "CAD": {
        "code": "CAD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "CDF": {
        "code": "CDF",
        "symbol_native": "FC",
        "decimal_digits": 2
    },
    "CHE": {
        "code": "CHE",
        "symbol_native": "CHE",
        "decimal_digits": 2
    },
    "CHF": {
        "code": "CHF",
        "symbol_native": "CHF",
        "decimal_digits": 2
    },
    "CHW": {
        "code": "CHW",
        "symbol_native": "CHW",
        "decimal_digits": 2
    },
    "CLF": {
        "code": "CLF",
        "symbol_native": "CLF",
        "decimal_digits": 4
    },
    "CLP": {
        "code": "CLP",
        "symbol_native": "$",
        "decimal_digits": 0
    },
    "CNH": {
        "code": "CNH",
        "symbol_native": "CNH",
        "decimal_digits": 2
    },
    "CNY": {
        "code": "CNY",
        "symbol_native": "¥",
        "decimal_digits": 2
    },
    "COP": {
        "code": "COP",
        "symbol_native": "$",
        "decimal_digits": 0
    },
    "COU": {
        "code": "COU",
        "symbol_native": "COU",
        "decimal_digits": 2
    },
    "CRC": {
        "code": "CRC",
        "symbol_native": "\u20A1",
        "decimal_digits": 2
    },
    "CUC": {
        "code": "CUC",
        "symbol_native": "CUC",
        "decimal_digits": 2
    },
    "CUP": {
        "code": "CUP",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "CVE": {
        "code": "CVE",
        "symbol_native": "\u200B",
        "decimal_digits": 2
    },
    "CZK": {
        "code": "CZK",
        "symbol_native": "Kč",
        "decimal_digits": 2
    },
    "DJF": {
        "code": "DJF",
        "symbol_native": "Fdj",
        "decimal_digits": 0
    },
    "DKK": {
        "code": "DKK",
        "symbol_native": "kr.",
        "decimal_digits": 2
    },
    "DOP": {
        "code": "DOP",
        "symbol_native": "RD$",
        "decimal_digits": 2
    },
    "DZD": {
        "code": "DZD",
        "symbol_native": "د.ج.\u200F",
        "decimal_digits": 2
    },
    "EGP": {
        "code": "EGP",
        "symbol_native": "ج.م.\u200F",
        "decimal_digits": 2
    },
    "ERN": {
        "code": "ERN",
        "symbol_native": "Nfk",
        "decimal_digits": 2
    },
    "ETB": {
        "code": "ETB",
        "symbol_native": "ብር",
        "decimal_digits": 2
    },
    "EUR": {
        "code": "EUR",
        "symbol_native": "\u20AC",
        "decimal_digits": 2
    },
    "FJD": {
        "code": "FJD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "FKP": {
        "code": "FKP",
        "symbol_native": "£",
        "decimal_digits": 2
    },
    "GBP": {
        "code": "GBP",
        "symbol_native": "£",
        "symbol_first": true,
        "decimal_digits": 2
    },
    "GEL": {
        "code": "GEL",
        "symbol_native": "\u20BE",
        "decimal_digits": 2
    },
    "GHS": {
        "code": "GHS",
        "symbol_native": "GH\u20B5",
        "decimal_digits": 2
    },
    "GIP": {
        "code": "GIP",
        "symbol_native": "£",
        "decimal_digits": 2
    },
    "GMD": {
        "code": "GMD",
        "symbol_native": "D",
        "decimal_digits": 2
    },
    "GNF": {
        "code": "GNF",
        "symbol_native": "FG",
        "decimal_digits": 0
    },
    "GTQ": {
        "code": "GTQ",
        "symbol_native": "Q",
        "decimal_digits": 2
    },
    "GYD": {
        "code": "GYD",
        "symbol_native": "$",
        "decimal_digits": 0
    },
    "HKD": {
        "code": "HKD",
        "symbol_native": "HK$",
        "decimal_digits": 2
    },
    "HNL": {
        "code": "HNL",
        "symbol_native": "L",
        "decimal_digits": 2
    },
    "HRK": {
        "code": "HRK",
        "symbol_native": "HRK",
        "decimal_digits": 2
    },
    "HTG": {
        "code": "HTG",
        "symbol_native": "G",
        "decimal_digits": 2
    },
    "HUF": {
        "code": "HUF",
        "symbol_native": "Ft",
        "decimal_digits": 2
    },
    "IDR": {
        "code": "IDR",
        "symbol_native": "Rp",
        "decimal_digits": 0
    },
    "ILS": {
        "code": "ILS",
        "symbol_native": "\u20AA",
        "decimal_digits": 2
    },
    "INR": {
        "code": "INR",
        "symbol_native": "\u20B9",
        "decimal_digits": 2
    },
    "IQD": {
        "code": "IQD",
        "symbol_native": "د.ع.\u200F",
        "decimal_digits": 0
    },
    "IRR": {
        "code": "IRR",
        "symbol_native": "IRR",
        "decimal_digits": 0
    },
    "ISK": {
        "code": "ISK",
        "symbol_native": "ISK",
        "decimal_digits": 0
    },
    "JMD": {
        "code": "JMD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "JOD": {
        "code": "JOD",
        "symbol_native": "د.أ.\u200F",
        "decimal_digits": 3
    },
    "JPY": {
        "code": "JPY",
        "symbol_native": "￥",
        "decimal_digits": 0
    },
    "KES": {
        "code": "KES",
        "symbol_native": "Ksh",
        "decimal_digits": 2
    },
    "KGS": {
        "code": "KGS",
        "symbol_native": "сом",
        "decimal_digits": 2
    },
    "KHR": {
        "code": "KHR",
        "symbol_native": "៛",
        "decimal_digits": 2
    },
    "KMF": {
        "code": "KMF",
        "symbol_native": "CF",
        "decimal_digits": 0
    },
    "KPW": {
        "code": "KPW",
        "symbol_native": "KPW",
        "decimal_digits": 0
    },
    "KRW": {
        "code": "KRW",
        "symbol_native": "\u20A9",
        "decimal_digits": 0
    },
    "KWD": {
        "code": "KWD",
        "symbol_native": "د.ك.\u200F",
        "decimal_digits": 3
    },
    "KYD": {
        "code": "KYD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "KZT": {
        "code": "KZT",
        "symbol_native": "\u20B8",
        "decimal_digits": 2
    },
    "LAK": {
        "code": "LAK",
        "symbol_native": "\u20AD",
        "decimal_digits": 0
    },
    "LBP": {
        "code": "LBP",
        "symbol_native": "ل.ل.\u200F",
        "decimal_digits": 0
    },
    "LKR": {
        "code": "LKR",
        "symbol_native": "රු.",
        "decimal_digits": 2
    },
    "LRD": {
        "code": "LRD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "LSL": {
        "code": "LSL",
        "symbol_native": "LSL",
        "decimal_digits": 2
    },
    "LYD": {
        "code": "LYD",
        "symbol_native": "د.ل.\u200F",
        "decimal_digits": 3
    },
    "MAD": {
        "code": "MAD",
        "symbol_native": "د.م.\u200F",
        "decimal_digits": 2
    },
    "MDL": {
        "code": "MDL",
        "symbol_native": "L",
        "decimal_digits": 2
    },
    "MGA": {
        "code": "MGA",
        "symbol_native": "Ar",
        "decimal_digits": 0
    },
    "MKD": {
        "code": "MKD",
        "symbol_native": "ден",
        "decimal_digits": 2
    },
    "MMK": {
        "code": "MMK",
        "symbol_native": "K",
        "decimal_digits": 0
    },
    "MNT": {
        "code": "MNT",
        "symbol_native": "\u20AE",
        "decimal_digits": 0
    },
    "MOP": {
        "code": "MOP",
        "symbol_native": "MOP$",
        "decimal_digits": 2
    },
    "MRO": {
        "code": "MRO",
        "symbol_native": "أ.م.\u200F",
        "decimal_digits": 0
    },
    "MUR": {
        "code": "MUR",
        "symbol_native": "Rs",
        "decimal_digits": 0
    },
    "MWK": {
        "code": "MWK",
        "symbol_native": "MK",
        "decimal_digits": 2
    },
    "MXN": {
        "code": "MXN",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "MXV": {
        "code": "MXV",
        "symbol_native": "MXV",
        "decimal_digits": 2
    },
    "MYR": {
        "code": "MYR",
        "symbol_native": "RM",
        "decimal_digits": 2
    },
    "MZN": {
        "code": "MZN",
        "symbol_native": "MTn",
        "decimal_digits": 2
    },
    "NAD": {
        "code": "NAD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "NGN": {
        "code": "NGN",
        "symbol_native": "\u20A6",
        "decimal_digits": 2
    },
    "NIO": {
        "code": "NIO",
        "symbol_native": "C$",
        "decimal_digits": 2
    },
    "NOK": {
        "code": "NOK",
        "symbol_native": "kr",
        "decimal_digits": 2
    },
    "NPR": {
        "code": "NPR",
        "symbol_native": "नेरू",
        "decimal_digits": 2
    },
    "NZD": {
        "code": "NZD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "OMR": {
        "code": "OMR",
        "symbol_native": "ر.ع.\u200F",
        "decimal_digits": 3
    },
    "PAB": {
        "code": "PAB",
        "symbol_native": "B\/.",
        "decimal_digits": 2
    },
    "PEN": {
        "code": "PEN",
        "symbol_native": "S\/",
        "decimal_digits": 2
    },
    "PGK": {
        "code": "PGK",
        "symbol_native": "K",
        "decimal_digits": 2
    },
    "PHP": {
        "code": "PHP",
        "symbol_native": "\u20B1",
        "decimal_digits": 2
    },
    "PKR": {
        "code": "PKR",
        "symbol_native": "Rs",
        "decimal_digits": 0
    },
    "PLN": {
        "code": "PLN",
        "symbol_native": "zł",
        "decimal_digits": 2
    },
    "PYG": {
        "code": "PYG",
        "symbol_native": "Gs.",
        "decimal_digits": 0
    },
    "QAR": {
        "code": "QAR",
        "symbol_native": "ر.ق.\u200F",
        "decimal_digits": 2
    },
    "RON": {
        "code": "RON",
        "symbol_native": "RON",
        "decimal_digits": 2
    },
    "RSD": {
        "code": "RSD",
        "symbol_native": "RSD",
        "decimal_digits": 0
    },
    "RUB": {
        "code": "RUB",
        "symbol_native": "\u20BD",
        "decimal_digits": 2
    },
    "RWF": {
        "code": "RWF",
        "symbol_native": "RF",
        "decimal_digits": 0
    },
    "SAR": {
        "code": "SAR",
        "symbol_native": "ر.س.\u200F",
        "decimal_digits": 2
    },
    "SBD": {
        "code": "SBD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "SCR": {
        "code": "SCR",
        "symbol_native": "SR",
        "decimal_digits": 2
    },
    "SDG": {
        "code": "SDG",
        "symbol_native": "ج.س.",
        "decimal_digits": 2
    },
    "SEK": {
        "code": "SEK",
        "symbol_native": "kr",
        "decimal_digits": 2
    },
    "SGD": {
        "code": "SGD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "SHP": {
        "code": "SHP",
        "symbol_native": "£",
        "decimal_digits": 2
    },
    "SLL": {
        "code": "SLL",
        "symbol_native": "Le",
        "decimal_digits": 0
    },
    "SOS": {
        "code": "SOS",
        "symbol_native": "S",
        "decimal_digits": 0
    },
    "SRD": {
        "code": "SRD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "SSP": {
        "code": "SSP",
        "symbol_native": "£",
        "decimal_digits": 2
    },
    "STN": {
        "code": "STN",
        "symbol_native": "STN",
        "decimal_digits": 2
    },
    "SYP": {
        "code": "SYP",
        "symbol_native": "ل.س.\u200F",
        "decimal_digits": 0
    },
    "SZL": {
        "code": "SZL",
        "symbol_native": "E",
        "decimal_digits": 2
    },
    "THB": {
        "code": "THB",
        "symbol_native": "THB",
        "decimal_digits": 2
    },
    "TJS": {
        "code": "TJS",
        "symbol_native": "сом.",
        "decimal_digits": 2
    },
    "TND": {
        "code": "TND",
        "symbol_native": "د.ت.\u200F",
        "decimal_digits": 3
    },
    "TOP": {
        "code": "TOP",
        "symbol_native": "T$",
        "decimal_digits": 2
    },
    "TRY": {
        "code": "TRY",
        "symbol_native": "\u20BA",
        "decimal_digits": 2
    },
    "TTD": {
        "code": "TTD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "TWD": {
        "code": "TWD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "TZS": {
        "code": "TZS",
        "symbol_native": "TSh",
        "decimal_digits": 0
    },
    "UAH": {
        "code": "UAH",
        "symbol_native": "\u20B4",
        "decimal_digits": 2
    },
    "UGX": {
        "code": "UGX",
        "symbol_native": "USh",
        "decimal_digits": 0
    },
    "USD": {
        "code": "USD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "USN": {
        "code": "USN",
        "symbol_native": "USN",
        "decimal_digits": 2
    },
    "UYI": {
        "code": "UYI",
        "symbol_native": "UYI",
        "decimal_digits": 0
    },
    "UYU": {
        "code": "UYU",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "UZS": {
        "code": "UZS",
        "symbol_native": "сўм",
        "decimal_digits": 0
    },
    "VEF": {
        "code": "VEF",
        "symbol_native": "Bs.",
        "decimal_digits": 2
    },
    "VND": {
        "code": "VND",
        "symbol_native": "\u20AB",
        "decimal_digits": 0
    },
    "VUV": {
        "code": "VUV",
        "symbol_native": "VT",
        "decimal_digits": 0
    },
    "WST": {
        "code": "WST",
        "symbol_native": "WS$",
        "decimal_digits": 2
    },
    "XAF": {
        "code": "XAF",
        "symbol_native": "FCFA",
        "decimal_digits": 0
    },
    "XCD": {
        "code": "XCD",
        "symbol_native": "$",
        "decimal_digits": 2
    },
    "XOF": {
        "code": "XOF",
        "symbol_native": "CFA",
        "decimal_digits": 0
    },
    "XPF": {
        "code": "XPF",
        "symbol_native": "FCFP",
        "decimal_digits": 0
    },
    "YER": {
        "code": "YER",
        "symbol_native": "ر.ي.\u200F",
        "decimal_digits": 0
    },
    "ZAR": {
        "code": "ZAR",
        "symbol_native": "R",
        "decimal_digits": 2
    },
    "ZMW": {
        "code": "ZMW",
        "symbol_native": "K",
        "decimal_digits": 2
    }
}
