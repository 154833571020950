import { CommonModule } from "@angular/common";
import { Component, Input, inject } from "@angular/core";
import { ControlContainer } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { CustomFormControl, CustomFormControlProps, CustomFormGroup } from "../../utils";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";

@Component({
    selector: 'tilby-map-coordinates',
    templateUrl: './tilby-map-coordinates.component.html',
    styleUrls: ['./tilby-map-coordinates.component.scss'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, MatCardModule, MatInputModule, TranslateModule],
})
export class TilbyMapCoordinatesComponent {

    @Input({required:true}) formControlLabel!: string | number;    
    private parentControl = inject(ControlContainer);

    protected parentForm!:CustomFormGroup<any>;
    protected control!:CustomFormControl;
    protected customProps: CustomFormControlProps<any,any> = new CustomFormControlProps();

    ngOnInit(){
        this.parentForm=this.parentControl.control as CustomFormGroup<any>;
        this.control=this.parentForm?.controls[this.formControlLabel] as CustomFormControl;
        this.customProps=this.control.customProps;
    }
}