import { Component, Input, inject } from "@angular/core";
import { ControlContainer, ReactiveFormsModule } from "@angular/forms";
import { CustomFormControl, CustomFormControlProps, CustomFormGroup } from "../../utils";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { MatRadioModule } from "@angular/material/radio";
import { StartEnd } from "../../models";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";


@Component({
    selector: 'tilby-radio-input',
    templateUrl: './tilby-radio-input.component.html',
    styleUrls: ['./tilby-radio-input.component.scss'],
    standalone: true,
    imports: [CommonModule, MatRadioModule, MatIconModule, ReactiveFormsModule, MatButtonModule, NgOptimizedImage, TranslateModule, MatInputModule],
})
export class TilbyRadioInputComponent {
    private parentControl = inject(ControlContainer);

    @Input({required:true}) formControlLabel!: string | number;
    @Input() flex: number = 1;
    @Input() hintAlign: StartEnd ='end';
    @Input() appearance: MatFormFieldAppearance ='fill';
    @Input() withCheckbox: boolean = false;

    protected parentForm!:CustomFormGroup<any>;
    protected control!:CustomFormControl;
    protected customProps: CustomFormControlProps<any,any> = new CustomFormControlProps();

    ngOnInit(){
        this.parentForm=this.parentControl.control as CustomFormGroup<any>;
        this.control=this.parentForm?.controls[this.formControlLabel] as CustomFormControl;
        this.customProps=this.control.customProps;
    }

    onImageError(event: any, defaultImage?: string) {
        event.target.src = defaultImage || "assets/images/128px-No-Image-Placeholder.png";
    }
}
