import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TilbyGesturesDirective } from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import {TilbyCurrencyPipe} from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { Items } from "tilby-models";

@Component({
    selector: 'tilby-item-element',
    standalone: true,
    imports: [CommonModule, TilbyCurrencyPipe, TilbyGesturesDirective],
    templateUrl: './tilby-item-element.component.html',
    styleUrls: ['./tilby-item-element.component.scss']
})
  export class TilbyItemElementComponent {
    @Input() item: any;
    @Input() showThumbnail: boolean = true;
    @Input() showUnavailableStock: boolean = false;
    @Input() priceIdentifier: string = 'price';
    @Input() priceWithCurrency: boolean = true;
    @Input() truncatePriceWithDots: boolean = true;
    @Input() getStockStatus: Function = () => {};
  
    @Output() itemTapped: EventEmitter<any> = new EventEmitter<any>();
    @Output() itemLongPressed: EventEmitter<any> = new EventEmitter<any>();

    itemTap(item: Items): void {
        this.itemTapped.emit(item);
    }

    itemPress(item: Items): void {
        this.itemLongPressed.emit(item);
    }
}