import {tilbyErrors} from "../tilby-magic-form-errors";
import {
    CustomAction,
    ICustomFormControlProps,
    InputConstraint,
    InputType,
    KeyValueIconImage,
    ListItem,
    PrefixSuffix,
    ControllerValue,
    ListIngredientComponent
} from "../../models";
import {CustomFormControl} from "./tilby-custom-form-control";
import {AbstractControl, FormGroup} from "@angular/forms";
import {countryToCode} from "@tilby/tilby-ui-lib/utilities";
import {CustomFormGroup} from "../form-group";

export class CustomFormControlProps<ValueType extends ControllerValue|{value:ControllerValue,disabled?:boolean},InputChoices extends string|number> implements ICustomFormControlProps<ValueType,InputChoices> {
    positionIndex?:number;
    label = '';
    inputType:InputType = 'text';
    inputChoices:InputChoices extends string?KeyValueIconImage<string>[]:KeyValueIconImage<number>[]= [];
    errors = tilbyErrors;
    datePickerActions = true;
    inputConstraint?: InputConstraint;
    customStyle?: { width?: string, alignItems?:string, justifyContent?:string, margin?:string };
    class?: string;
    matErrorClass?:string;
    componentClass?: string;
    matElementClass?: string;
    matElementSelected?: string;
    iconClass?: string;
    id?: string;
    focusInitial?: boolean;
    hint?: () => string;
    icon?: PrefixSuffix<string>;
    inputInitialValue?: ValueType;
    inputTypeInitial?: InputType;
    placeholder?:string;
    loopTrackBy?:(index:number,item:any)=>number|string;
    listItems?:ListItem[];
    listItemSwipe?: (swipe: 'left'|'right', item:ListIngredientComponent) => void;
    customActions?:PrefixSuffix<CustomAction>;
    readonly?:boolean;
}
type Control = FormGroup|CustomFormControl|AbstractControl;

export const labeling = <T extends Control|{[key:string]:Control}>(controls: T,labelMap:Map<string,string>) => {
    Object.entries(controls)
        .forEach(([form_field, form_control]) =>
            'controls' in form_control
                ? labeling<FormGroup>(form_control.controls,labelMap)
                : form_control.customProps.label = labelMap.get(form_field) || form_field
        )
};

export const hideFields = <T extends Control|{[key:string]:Control}>(controls: T,hiddenFields:Array<string>) => {
    Object.entries(controls)
        .forEach(([form_field, form_control]) =>
            'controls' in form_control
                ? hideFields<FormGroup>(form_control.controls,hiddenFields)
                : hiddenFields.indexOf(form_field)>-1 && (form_control.customProps.inputType = 'hidden' && form_control.disable())
        )
};

export const flatterize = <T extends {}>(obj:T): any =>
    Object.entries(obj)
    .reduce((s,[k,v])=>
        ({...s,
            ...((typeof v === 'object' && v !== null)
                ?{...flatterize(v)}
                :{[k]:v})}),
        {} as T);

export const callbackToGrid = <T extends {}>(obj:T): any => {
    const flatObj = flatterize(obj);
    return Object.entries<string>(flatObj).reduce((s,[k,v])=> ({
        ...s,
        [k]:countryToCode(k, v)||v
    }),{});
}

export const sortedControlsInFormGroup = (formGroup:CustomFormGroup<{ [key:string]:CustomFormControl<any>|CustomFormGroup<any> }>) =>
    Object.fromEntries(Object.entries(formGroup.controls).sort(([, va], [, vb]) => (va.customProps?.positionIndex||0)-(vb.customProps?.positionIndex||0)));

