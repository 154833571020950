import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SalesItemsPriceChanges, SalesPriceChanges} from "tilby-models";
import {
    PriceChangeDescriptionObj,
    PriceChangesType,
    TilbyPriceChangePipe
} from "@tilby/tilby-ui-lib/pipes/tilby-price-change";
import {TilbyCurrencyPipe} from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import {TilbyGesturesDirective} from "@tilby/tilby-ui-lib/directives/tilby-gestures";

export type PriceChangeBase = Pick<SalesItemsPriceChanges&SalesPriceChanges,'description'|'value'|'type'|'index'|'amount'>&any;

function orderByIndex(priceChanges: PriceChangeBase[]){
    return priceChanges.sort(({index:a},{index:b})=>a-b);
}

@Component({
    selector: 'tilby-price-changes',
    standalone: true,
    imports: [CommonModule, TilbyPriceChangePipe, TilbyCurrencyPipe, TilbyGesturesDirective],
    templateUrl: './price-changes.component.html',
    styleUrls: ['./price-changes.component.scss']
})
export class PriceChangesComponent {

    @Input({alias:'priceChanges',required:true,transform:orderByIndex}) priceChangesOrderedByIndex: PriceChangeBase[]=[];
    @Input({required:true}) priceChangeDescriptions!: PriceChangeDescriptionObj;
    @Input() customClass?: string;
    @Input() priceChangeTypesToHide: PriceChangesType[] = [];
    @Output() removePriceChange = new EventEmitter<PriceChangeBase>();

}
