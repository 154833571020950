import {Component, ElementRef, Inject} from '@angular/core';
import {CommonModule, NgIf} from '@angular/common';
import {TilbyTimeComponent} from "../tilby-time";
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {TimePicker} from "@tilby/tilby-ui-lib/pipes/tilby-date";
import {MatButtonModule} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";
export interface TilbyTimeDialogData{ trigger: ElementRef, timePickerControl: FormControl<TimePicker> }
@Component({
  selector: 'tilby-tilby-time-dialog',
  standalone: true,
  imports: [CommonModule, TilbyTimeComponent, ReactiveFormsModule, MatButtonModule, MatDialogModule, NgIf, TranslateModule],
  templateUrl: './tilby-time-dialog.component.html',
  styleUrls: ['./tilby-time-dialog.component.scss']
})
export class TilbyTimeDialogComponent {
  constructor(private _matDialogRef: MatDialogRef<TilbyTimeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) protected data: TilbyTimeDialogData) {
  }

  ngOnInit() {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const timeInputElementRef = this.data.trigger.nativeElement;
    const rect = timeInputElementRef.getBoundingClientRect();
    const rem = 16;
    const dialogWidth = 9;
    const dialogHeight = 24;
    const showDown = (window.innerHeight-rect.top-timeInputElementRef.offsetHeight>dialogHeight*rem);
    matDialogConfig.position = {
      //right: `${rect.right-dialogWidth*rem+(showDown?0:rem/2)}px`,
      left: `${rect.left}px`,
      ...(showDown&&{top: `${rect.bottom-rem}px`}),
      ...(!showDown&&{top: `${rect.top-rem*(dialogHeight)}px`})
    };
    matDialogConfig.width = `${dialogWidth}rem`;
    matDialogConfig.height = `${dialogHeight}rem`;
    this._matDialogRef.updateSize(matDialogConfig.width, matDialogConfig.height);
    this._matDialogRef.updatePosition(matDialogConfig.position);
  }
  cancel(): void {
    this._matDialogRef.close(null);
  }
}
