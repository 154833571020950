import { Component, Input, inject } from "@angular/core";
import { ControlContainer, ReactiveFormsModule } from "@angular/forms";
import { CustomFormControl, CustomFormControlProps, CustomFormGroup } from "../../utils";
import { CommonModule, NgClass, NgForOf, NgIf, NgStyle, NgOptimizedImage } from "@angular/common";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
import { KeyValueIconImage, StartEnd } from "../../models";
import { MatFormFieldAppearance } from "@angular/material/form-field";


@Component({
    selector: 'tilby-select-input',
    templateUrl: './tilby-select-input.component.html',
    styleUrls: ['./tilby-select-input.component.scss'],
    standalone: true,
    imports: [CommonModule, MatInputModule, MatIconModule, MatSelectModule, ReactiveFormsModule, MatButtonModule, NgStyle, NgForOf, NgClass, NgIf, NgOptimizedImage, TranslateModule],
})
export class TilbySelectInputComponent {
    @Input() flex: number = 1;
    @Input({required:true}) formControlLabel!: string | number;
    @Input() appearance: MatFormFieldAppearance='fill';
    @Input() multiple: boolean = false;
    @Input() hintAlign: StartEnd ='end';
    
    private parentControl = inject(ControlContainer);
    protected parentForm!:CustomFormGroup<any>;
    protected control!:CustomFormControl;
    protected customProps: CustomFormControlProps<any,any> = new CustomFormControlProps();

    //Keep the memory of the selected imageOrIcon
    protected selectedIcon?:string;
    protected selectedImage?:string;
    protected selectedDefaultImage?:string;
    
    ngOnInit(){
        this.parentForm=this.parentControl.control as CustomFormGroup<any>;
        this.control=this.parentForm?.controls[this.formControlLabel] as CustomFormControl;
        this.customProps=this.control.customProps;
    }

    protected setIconImage($event: MatSelectChange, choises: KeyValueIconImage<string|number>[]) {
        const choise = choises.find((c) => c.value==$event.value);
        this.selectedIcon = choise?.icon;
        this.selectedImage = choise?.image;
        this.selectedDefaultImage = choise?.defaultImage || "assets/images/128px-No-Image-Placeholder.png";
    }

    onImageError(event: any, defaultImage?: string) {
        event.target.src = defaultImage || "assets/images/128px-No-Image-Placeholder.png";
    }
}