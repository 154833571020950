import {AbstractControlOptions, FormControl, Validators} from "@angular/forms";
import {CustomFormControlProps} from "./custom-form-control-props";
import {ControllerValue} from "../../models";

export class CustomFormControl<ValueType extends ControllerValue|{value:ControllerValue,disabled?:boolean} =any> extends FormControl implements FormControl<ValueType> {

    constructor(
        initialValue: ValueType,
        validatorOrOpts: AbstractControlOptions = {},
        public customProps = new CustomFormControlProps()
    ) {
        if((customProps.inputType==='email'||customProps.inputType==='email-with-checkbox')){
            validatorOrOpts.validators = [...(!!validatorOrOpts?.validators? (Array.isArray(validatorOrOpts?.validators)?validatorOrOpts?.validators:[validatorOrOpts?.validators]):[] ),Validators.email ];
        }
        customProps.inputInitialValue=initialValue;
        customProps.inputTypeInitial=customProps.inputType;
        super(initialValue, validatorOrOpts, validatorOrOpts?.asyncValidators);
    }
}
