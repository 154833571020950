import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatTabsModule} from "@angular/material/tabs";
import {NgForOf, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

export type TilbyDialogsTab = { title:string,id:number };

@Component({
    selector: 'tilby-dialog-tabs',
    standalone: true,
    templateUrl: './tilby-dialog-tabs.component.html',
    imports: [
        MatTabsModule,
        NgForOf,
        NgIf,
        TranslateModule
    ],
    styleUrls: ['./tilby-dialog-tabs.component.scss']
})
export class TilbyDialogTabsComponent {
    @Input({required:true}) tabs: TilbyDialogsTab[] = [];
    @Output() tabSelected = new EventEmitter<TilbyDialogsTab>();
}
