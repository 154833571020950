import {
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter, inject,
    Input,
    Output,
    QueryList,
    Renderer2,
    ViewChildren
} from '@angular/core';
import {AbstractControl, ReactiveFormsModule} from "@angular/forms";
import {CommonModule, KeyValue} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {
    TilbyFormControlElementComponent
} from "./tilby-form-control-element";
import {CustomFormControl, CustomFormGroup} from "./utils";
import {TranslateModule} from "@ngx-translate/core";
import { MatFormFieldModule} from "@angular/material/form-field";

@Component({
    standalone: true,
    imports: [
        ReactiveFormsModule,
        CommonModule,
        MatButtonModule,
        TilbyFormControlElementComponent,
        TranslateModule,
        MatFormFieldModule
    ],
    selector: 'tilby-magic-form',
    templateUrl: './tilby-magic-form.component.html',
    styleUrls: ['./tilby-magic-form.component.scss'],
})
export class TilbyMagicFormComponent {
    private renderer2 = inject(Renderer2);

    @Input() allFieldWidth:string = 'auto';
    @Input() allWithCheckbox:boolean = false;
    @Input() showButtonSubmit:boolean = false;
    @Input() form:CustomFormGroup<any> = new CustomFormGroup<any>({});
    @Output() formSubmit = new EventEmitter();

    @ViewChildren('contentProjection') elementsToShow?: QueryList<ElementRef<HTMLElement>>;
    @ContentChildren('contentProjection', { read: ElementRef }) childTemplate?: QueryList<ElementRef<HTMLElement>>;

    protected trackByFn(index: number, control: KeyValue<any,any>): string {
        return control.key;
    }
    ngOnInit() {}

    submitForm() {
        this.formSubmit.emit();
    }

    defaultOrder(a:KeyValue<unknown, unknown>,b:KeyValue<unknown, unknown>) {
        const aPositionIndex= (a.value as any as CustomFormControl<typeof a>|CustomFormGroup<{[p in keyof (typeof a)]:AbstractControl<any,any>}>).customProps?.positionIndex||0;
        const bPositionIndex= (b.value as any as CustomFormControl<typeof b>|CustomFormGroup<{[p in keyof (typeof b)]:AbstractControl<any,any>}>).customProps?.positionIndex||0;
        return aPositionIndex-bPositionIndex;
    }

    ngAfterViewInit() {
        this.childTemplate?.forEach((c, i) =>
            this.renderer2.appendChild(
                this.elementsToShow?.get(i)?.nativeElement,
                c.nativeElement
            )
        );
    }
}
