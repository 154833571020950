import {Component, Input, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RoomsTables} from 'tilby-models';
import {matchAllWords} from "@tilby/tilby-ui-lib/utilities";
import {Color,ICON_TO_SVG_ELEMENTS, ICON_TO_SVG_TABLES, STATIC_ELEMENT_PREFIX} from './tilby-tables.model';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import { MatIconModule } from '@angular/material/icon';

type ColorsHEX = '#009688' | '#B71C1C' | '#E88737' | '#FFEA00' | '#E0E0E0' | '#00BCD4';
type ColorState = {
    name:Color,
    HEX:ColorsHEX,
    isSelected: boolean,
    showCovers:boolean,
    showCoversIcon?:boolean,
    showMinutes:boolean,
    showReserved:boolean,
    showExitsAndFinalAmount:boolean,
    class?:string,
    billLock?:boolean
}

@Component({
  selector: 'tilby-table-element',
  standalone: true,
  imports: [CommonModule,TilbyCurrencyPipe, MatIconModule],
  templateUrl: './tilby-table-element.component.html',
  styleUrls: ['./tilby-table-element.component.scss']
})
export class TilbyTableElementComponent {
    protected readonly STATIC_ELEMENT_PREFIX = STATIC_ELEMENT_PREFIX;

    protected tableName='';
    protected width=0;
    protected height=0;
    protected minSize=0;
    @Input() public scale=1;
    @Input() public covers=0;
    @Input() public tableMultipleBusyCovers=0;
    @Input() public isTableMultipleOrSingleWithMultipleSales=false;
    @Input() public exitsSent=0;
    @Input() public exitsTotalInUse=5;
    @Input() public finalAmount?:number;
    @Input() public tableBookingWarning=false;
    @Input() public tableHanging=false;
    @Input() public disableTableInfo=false;
    @Input() public saleNameToShow?:string;
    @Input() public disabledToggleShowAmount=false;

    @Input() minutes="0'";
    protected shape='circle';
    protected colors = new Map<Color,ColorState>([
        ['green', {name:'green', HEX:'#009688',showCovers:true,showMinutes:false,showReserved:true,showExitsAndFinalAmount:false,isSelected:false,class:'tw-fill-white'}],
        ['red', {name:'red', HEX:'#B71C1C',showCovers:true,showCoversIcon:true,showMinutes:true,showReserved:true,showExitsAndFinalAmount:true,isSelected:false,class:'tw-fill-white'}],
        ['orange', {name:'orange', HEX:'#E88737',showCovers:true,showMinutes:true,showReserved:true,showExitsAndFinalAmount:true,isSelected:false,class:'tw-fill-white'}],
        ['yellow', {name:'yellow', HEX:'#FFEA00',showCovers:true,showCoversIcon:true,showMinutes:true,showReserved:true,showExitsAndFinalAmount:true,isSelected:false,billLock:true,class:'tw-fill-black'}],
        ['grey', {name:'grey', HEX:'#E0E0E0',showCovers:false,showMinutes:false,showReserved:false,showExitsAndFinalAmount:false,isSelected:false,class:'tw-fill-white'}],
        ['blue', {name:'blue', HEX:'#00BCD4',showCovers:false,showMinutes:false,showReserved:false,showExitsAndFinalAmount:false,isSelected:true,class:'tw-fill-white'}],
    ]);
    protected colorState=this.colors.get(this.color);
    protected svg = ICON_TO_SVG_TABLES.circle4;

    protected toggleShowAmount=true;

    @Input() billLockForEditMode=false;
    @Input() table?:RoomsTables;

    private _color: Color='green';
    @Input() set color(color:Color){
        this._color=color;
        this.colorState= this.colors.get(color);
    }
    get color(){
        return this._color;
    }
    get isTable(){
        return !this.shape?.startsWith(STATIC_ELEMENT_PREFIX);
    }

    private interval=setInterval(()=> this.toggleShowAmount = (this.finalAmount && this.finalAmount>0)? !this.toggleShowAmount : true, 2000);

    ngOnChanges(changes:SimpleChanges) {
        if(this.table&&(changes['table']?.currentValue||changes['scale']?.currentValue)){
            const table=this.table;
            this.tableName=table.name;
            this.width=table.width*this.scale;
            this.height=table.height*this.scale;
            this.minSize=(this.width<this.height?this.width:this.height);
            this.shape=matchAllWords(table.shape)?.[0]||'square';

            // @ts-ignore
            this.svg=this.isTable?ICON_TO_SVG_TABLES[table.shape]:ICON_TO_SVG_ELEMENTS[table.shape];
        }
    }
    ngOnDestroy(){
        clearInterval(this.interval);
    }
}
