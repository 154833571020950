import {AbstractControl, AbstractControlOptions, FormArray, FormGroup} from "@angular/forms";
import {CustomFormArrayProps, CustomFormGroupProps} from "./custom-form-group-props";

export class CustomFormGroup<T extends { [K in keyof T]: AbstractControl<any, any>; }> extends FormGroup<T> {

    constructor(
        initialValue: T,
        validatorOrOpts: AbstractControlOptions = {},
        public customProps = new CustomFormGroupProps()
    ) {
        super(initialValue, validatorOrOpts?.validators, validatorOrOpts?.asyncValidators);
    }
}

export class CustomFormArray<T extends AbstractControl<any, any>> extends FormArray<T> {

    constructor(
        initialValue: T[],
        validatorOrOpts: AbstractControlOptions = {},
        public customProps = new CustomFormArrayProps()
    ) {
        super(initialValue, validatorOrOpts, validatorOrOpts?.asyncValidators);
    }
}
