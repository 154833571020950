import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";
import {TilbyGeneralInputButtonComponent} from "../tilby-general-input-button";
import { GeneralInputButton } from '@tilby/tilby-ui-lib/models';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'tilby-general-input-button-panel',
    standalone: true,
    imports: [CommonModule, MatIconModule, MatMenuModule, MatButtonModule, TranslateModule, TilbyGeneralInputButtonComponent, MatFormFieldModule],
    templateUrl: './tilby-general-input-button-panel.component.html',
    styleUrls: ['./tilby-general-input-button-panel.component.scss']
})
export class TilbyGeneralInputButtonPanelComponent {

    @Input() btn: GeneralInputButton = {
        isIt: ()=>true,
        name:'',
        icon:()=>'more_vert',
        click:()=>{}
    };
    @Input({required:true}) panelButtons!: GeneralInputButton[];
}
