import {Component, ElementRef, EventEmitter, inject, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Departments, SalesItemsPriceChanges} from 'tilby-models';
import {TranslateModule} from "@ngx-translate/core";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {Observable} from "rxjs";

import {TilbyCurrencyPipe} from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import {TilbyGesturesDirective} from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import {TilbyResizeVirtualScrollDirective} from "@tilby/tilby-ui-lib/directives/tilby-resize-virtual-scroll";
import {TilbyCustomVirtualScrollDirective} from "@tilby/tilby-ui-lib/directives/tilby-custom-virtual-scroll-strategy";
import {Exit, PriceChangePlus, RemovePriceChangeFromSaleItem,SaleItemWithMenu, ShopCartSalesEdited, ShopCartSalesItems} from "../tilby-order.model";
import {sleep} from "@tilby/tilby-ui-lib/utilities";
import {PriceChangeDescriptionObj, TilbyPriceChangePipe} from "@tilby/tilby-ui-lib/pipes/tilby-price-change";
import {
    PriceChangesComponent
} from "./price-changes/price-changes.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

//Gestisce undefined e null
const groupBy = <T extends Record<string, any>>(key: keyof T) => (arr: T[]):Record<T[keyof T],T[]> =>arr.reduce((s, { [key]: key_value=0 as T[keyof T], ...v }) => ({...s,[key_value||0]: [...(s[key_value||0] || []), { [key]: key_value||undefined, ...v }]}),{} as Record<T[keyof T]|0,T[]>);

@Component({
  selector: 'tilby-shop-cart',
  standalone: true,
    imports: [CommonModule, MatDividerModule, CdkVirtualForOf, MatListModule, MatIconModule, TranslateModule, CdkVirtualScrollViewport, TilbyCustomVirtualScrollDirective, TilbyCurrencyPipe, TilbyResizeVirtualScrollDirective, TilbyGesturesDirective, CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport, TilbyPriceChangePipe, PriceChangesComponent, MatProgressSpinnerModule],
  templateUrl: './tilby-shop-cart.component.html',
  styleUrls: ['./tilby-shop-cart.component.scss'],
    host:{class:"tw-h-full tw-flex tw-flex-col"}
})
export class TilbyShopCartComponent implements OnInit {
    protected readonly Math = Math;

    @ViewChild('shopCartViewport',{read:CdkVirtualScrollViewport}) shopCartViewport?: CdkVirtualScrollViewport;
    @ViewChildren('item',{read:ElementRef}) items?:QueryList<ElementRef<HTMLElement>>;

    private _sale?:ShopCartSalesEdited;
    get sale(){
        return this._sale;
    }
    @Input({required:true})lastUuidChanged:string|undefined;
    @Input({required:true})lastUuidSelected:string|undefined;
    @Input({required:true}) set sale(sale:ShopCartSalesEdited|undefined){
        if(!sale) return;
        const {itemHeights,itemHeightsWithExits} = this.setItemHeights(sale);
        this.itemHeights = itemHeights;
        this.itemHeightsWithExits = itemHeightsWithExits;
        this._sale = sale;
        this.saleByKey =groupBy<SaleItemWithMenu>('exit')(sale.sale_items||[]);
        setTimeout(()=>this.scrollAndFlashLastChangedItem(sale));
    };
    @Input({required:true}) departments: Departments[]=[];
    @Input({required:true}) exitsArray$!: Observable<Exit[]>;
    @Input({required:true}) groupItemUuid?: string='';
    @Input({required:true}) priceChangeDescriptions!: PriceChangeDescriptionObj;
    @Input() ingredientsRemovalAffectsPrice=false;
    @Input() showAllExits=false;
    @Input() disableExitStatus=false;
    @Input() compactMode=false;
    @Input() coverConfiguration = { automated_add_cover: undefined, type: undefined, value: undefined };

    @Output() swipeLeftHandlerEmitter= new EventEmitter<SaleItemWithMenu>() ;
    @Output() swipeRightHandlerEmitter= new EventEmitter<SaleItemWithMenu>() ;
    @Output() longPressHandlerEmitter= new EventEmitter<SaleItemWithMenu>() ;
    @Output() removePriceChangeFromSaleItemEmitter= new EventEmitter<RemovePriceChangeFromSaleItem>() ;
    @Output() removePriceChangeFromSaleEmitter= new EventEmitter<PriceChangePlus>() ;
    @Output() exitChange= new EventEmitter<Exit>() ;
    @Output() sendExit= new EventEmitter<Exit>() ;

    @Output() clickHandlerEmitter= new EventEmitter<{ saleItem:SaleItemWithMenu,isSelected:boolean }>() ;
    @Output() lastUuidChangedEmitter= new EventEmitter<SaleItemWithMenu>() ;
    private readonly elementRef=inject(ElementRef);
    protected saleByKey!:Record<number,SaleItemWithMenu[]>;

    private itemSize=60;
    protected itemHeights:number[]=[];
    protected itemHeightsWithExits:number[]=[];
    protected isSelected=false;
    protected clickEnabled=true;
    protected hasCoverConfiguration : undefined | boolean = undefined;

    private log(...arg:any[]){
        // console.debug('SHOP_CART: ',...arg)
    }

    ngOnInit(): void {
        this.hasCoverConfiguration = (this.coverConfiguration.automated_add_cover === 'on' &&  this.coverConfiguration.type === 'id' && this.coverConfiguration.value !== undefined ) ? true : false;
    }
    
    private setItemHeights({sale_items}:ShopCartSalesEdited) {
        const sumOfHeights = (saleItem : ShopCartSalesItems & SaleItemWithMenu) => ((saleItem.variations?.length||0)+(saleItem.price_changes?.length||0))*20+((saleItem.ingredients?.length||0)*16)+(saleItem.$menuIndex?24:0)+this.itemSize;
        const exitToShow = (sale_items:(ShopCartSalesItems & SaleItemWithMenu)[], sale_item:(ShopCartSalesItems & SaleItemWithMenu), i:number) => sale_item.exit && sale_items?.[i-1]?.exit!=sale_item.exit;
        const itemHeights = sale_items?.map((item,_i,_array)=>sumOfHeights(item))||[];
        const itemHeightsWithExits = sale_items?.map((item,i,array)=>1+(exitToShow(array,item,i)?30:0)+sumOfHeights(item))||[];
        this.log('SET_ITEM_HEIGHTS',itemHeights)
        return {itemHeights,itemHeightsWithExits};
    }
    private async scrollAndFlashLastChangedItem(sale:ShopCartSalesEdited) {
        let element = this.items?.find(item=>item.nativeElement.id==`${this.lastUuidChanged}`)?.nativeElement;
        const scrollToIndex= sale.sale_items?.findIndex((item,_index)=> {
            this.log('scrollToIndex',item.uuid,this.lastUuidChanged,item.name)
            return item.uuid == `${this.lastUuidChanged}`
        })||0;
        const isInViewport = (element:HTMLElement) => {
            const rect = element.getBoundingClientRect();
            const host = this.elementRef.nativeElement.getBoundingClientRect();
            const isVisible= ((rect.top >= host.top) && (rect.bottom <= host.bottom));
            console.log('IS_IN_VIEWPORT',isVisible,rect,this.elementRef.nativeElement.getBoundingClientRect());
            return isVisible;
        };
        if(scrollToIndex>=0&&!element&&this.shopCartViewport) {
            //behaviour smooth is too slow and can break the app
            this.shopCartViewport?.scrollToIndex(scrollToIndex);

            let retries=10;
            while (!element&&retries--){
                element = this.items?.find(item=>item.nativeElement.id==`${this.lastUuidChanged}`)?.nativeElement;
                await sleep(100);
                this.log('ELEMENT', {element, scrollToIndex,retries})
            }
        }
        if(element){
            if(!isInViewport(element)) element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            element.classList.add('tilby-frame-primary');
            setTimeout(()=>element?.classList.remove('tilby-frame-primary'),6000);
            if(sale.sale_items)this.lastUuidChangedEmitter.emit(sale.sale_items[scrollToIndex])
        }

    }

    protected swipeLeftHandler(modifySaleItem:SaleItemWithMenu) {
        this.clickEnabled=false;
        this.swipeLeftHandlerEmitter.emit(modifySaleItem);
    }
    protected swipeRightHandler(modifySaleItem:SaleItemWithMenu) {
        this.clickEnabled=false;
        this.swipeRightHandlerEmitter.emit(modifySaleItem);
    }
    protected longPressHandler(sale_item:SaleItemWithMenu) {
        this.clickEnabled=false;
        this.longPressHandlerEmitter.emit(sale_item);
    }
    protected removePriceChangeFromSaleItem(saleItem: SaleItemWithMenu, priceChange: SalesItemsPriceChanges) {
        this.removePriceChangeFromSaleItemEmitter.emit({saleItem, priceChange})
    }
    protected removePriceChangeFromSale(priceChange: PriceChangePlus) {
        this.removePriceChangeFromSaleEmitter.emit(priceChange);
    }
    protected findDepartmentById(department_id: number) {
        return this.departments.find(({id=''})=>id==department_id);
    }
    protected clickHandler(sale_item: SaleItemWithMenu) {
        if(!this.clickEnabled){
            return;
        }
        this.isSelected=(sale_item.uuid == this.lastUuidSelected)?(!this.isSelected):true;
        this.clickHandlerEmitter.emit({saleItem:sale_item,isSelected:this.isSelected})}

    protected clickExitHandler(exit: Exit) {
        this.exitChange.emit(exit);
    }

    protected enableClick() {
        this.clickEnabled=true;
    }
}
