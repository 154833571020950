import {Component, ElementRef, forwardRef, inject, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {CommonModule} from "@angular/common";
import {TilbyDatePipe,TimePicker, TimePickerExtended} from "@tilby/tilby-ui-lib/pipes/tilby-date";
import {TranslateModule} from "@ngx-translate/core";
import {TilbyTimeComponent} from "./tilby-time/tilby-time.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {TilbyTimeDialogComponent, TilbyTimeDialogData} from "./tilby-time-dialog";
import {MatDialog} from "@angular/material/dialog";
import {CustomFormControl} from "../../utils/form-control";

@Component({
  selector: 'tilby-time-picker',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    TilbyDatePipe,
    TranslateModule,
    TilbyTimeComponent,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule
  ],
  templateUrl: './tilby-time-picker.component.html',
  styleUrls: ['./tilby-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TilbyTimePickerComponent),
      multi: true,
    }
  ]


})
export class TilbyTimePickerComponent implements ControlValueAccessor {
  @ViewChild('timePickerField',{read:ElementRef}) timePickerField!: ElementRef<HTMLElement>;
  private readonly tilbyDatePipe = inject(TilbyDatePipe);

  private _timePicker = this.setTimePicker(this.getShopDateTime(TilbyDatePipe.date(),'HH:mm')||'00:00');
  get timePicker(){
    return this._timePicker;
  }
  set timePicker(time:TimePickerExtended){
    this._timePicker=time;
    this.timePickerControl.setValue(time);
  }
  timePickerAtOpen=this.timePicker;
  timePickerControl = new CustomFormControl<TimePicker>(this.timePicker);

  @Input() label: string = 'Choose time';

  constructor(private _matDialogRef: MatDialog) {
  }

  protected isDisabled = false;

  protected showTimePicker(event: MouseEvent){
    const target = this.timePickerField;
    this._matDialogRef.open<TilbyTimeDialogComponent,TilbyTimeDialogData,TimePicker>(TilbyTimeDialogComponent, {
      data: { trigger: target,timePickerControl:this.timePickerControl},
      backdropClass: 'tw-bg-transparent',
    }).afterClosed().subscribe( res => {
      if(res){
        this.timePicker= {...res,time:`${res.h}:${res.m}`}
        this._onChange(this.timePicker.time);
      } else {
        const {time} = this.timePicker;
        this.timePickerControl.setValue({h:time.split(':')[0],m:time.split(':')[1]});
      }
    });
  };

  private setTimePicker(time: string): TimePickerExtended {
    return {
      time: time ||'00:00',
      h: time.split(':')[0] || '00',
      m: time.split(':')[1] || '00'
    }
  }

  private getShopDateTime(date: string, format: string): string | null {
    return this.tilbyDatePipe.transform(date, format) || '';
  }
//START - ControlValueAccessor
  public writeValue(time: string): void {
    if(time){
      this.timePicker = this.setTimePicker(time);
    }
  }

  private _onChange = (_value: string | null): void => undefined;
  public registerOnChange(fn: (value: string | null) => void): void {
    this._onChange = (value: string | null) => {
      fn(value);
    };
  }

  public onTouched = (): void => undefined;
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
// END - ControlValueAccessor

  updatePicker($event: any) {
    if ($event.target?.value) {
      this.timePicker = this.setTimePicker($event.target.value);
    }
  }
}
