import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatDialogContent, MatDialogModule} from "@angular/material/dialog";
import { mobileCheck } from '@tilby/tilby-ui-lib/utilities';

@Component({
  selector: 'tilby-dialog-content',
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  templateUrl: './tilby-dialog-content.component.html',
  styleUrls: ['./tilby-dialog-content.component.scss'],
  hostDirectives:[MatDialogContent],
  host: {
    class: "tw-bg-[inherit] tw-h-full tw-flex-1 tw-justify-self-start tw-p-0"
  }
})
export class TilbyDialogContentComponent {
  private readonly mobileCheck = mobileCheck();

  @HostBinding('class.tw-max-h-full') get myClass() {
    return this.mobileCheck;
  }

  // INPUT BINDING
  @HostBinding('class.tw-p-0') @Input() hideToolbar: boolean = false;
  @HostBinding('class.pointer-events-none-for-all-children') @Input() showProgressBar = false;


}
