import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'autocompleteFiltered',
    standalone: true
})
export class AutocompleteFilteredPipe implements PipeTransform {

    private _filter(value='',list:KeyValue<string,string>[]=[]): KeyValue<string,string>[] {
        if(!value){
            return list;
        }
        const filterValue = value.toLowerCase();
        return list.filter(({key, value}:KeyValue<string,string>) => key.toLowerCase().includes(filterValue) || value.toLowerCase().includes(filterValue));

    }

    transform(field:string|undefined,list:KeyValue<string,string>[]): KeyValue<string,string>[]|undefined {
        return this._filter(field,list);
    }

}
