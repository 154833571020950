import { HttpClient } from '@angular/common/http';
import { Directive, inject, signal } from '@angular/core';
import {
    AbstractControl,
    AsyncValidator,
    NG_ASYNC_VALIDATORS,
} from '@angular/forms';
import {
    lastValueFrom,
} from 'rxjs';

export const DEFAULT_IMAGE_EXTENSION = '.png';
export const DEFAULT_ACCEPTED_EXTENSION = ['.png','.jpg','.jpeg','.mp4'];

@Directive({
    selector: '[tilbyImageHandlerValidator]',
    standalone: true,
    providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: TilbyImageHandlerValidatorDirective,
        multi: true
    }]
})
export class TilbyImageHandlerValidatorDirective implements AsyncValidator {
    private http = inject(HttpClient);
    public mediaExtension = signal(DEFAULT_IMAGE_EXTENSION);
    public tryingToLoad = signal<boolean>(false);
    public acceptedExtensions = signal<string[]>(DEFAULT_ACCEPTED_EXTENSION);

    private async getExtension(url: string) {
        if(!url) return;
        // START - TRY TO GET EXTENSION BY URL
        const extensionByUrl = `.${url.split('.').pop()}`;
        if(this.acceptedExtensions().includes(extensionByUrl)){
            return this.mediaExtension.set(extensionByUrl);
        }
        // END - TRY TO GET EXTENSION BY URL

        // IF URL HASN'T EXTENSION TRY TO UNDERSTAND THE CONTENT BY CONTENT-TYPE IN REQUEST
        let response: any;
        try{
            response = await lastValueFrom(this.http.head(url, {responseType: 'blob'}));
            const contentType = response.type;
            if (!(contentType && (contentType.startsWith('image/') || contentType.startsWith('video/')))){
                throw new Error('HEAD ERROR');
            }
        } catch(e) {
            response = await lastValueFrom(this.http.get(url, {responseType: 'blob'}));
        }

        const contentType = response.type;
        if (contentType && (contentType.startsWith('image/') || contentType.startsWith('video/'))) {
            this.mediaExtension.set(`.${contentType.split('/').pop()}`);
        } else {
            this.mediaExtension.set(DEFAULT_IMAGE_EXTENSION);
            throw new Error('Invalid image type, default extension used: .png');
        }

        return this.mediaExtension();
    }

    async validate(control: AbstractControl) {
        if(!control.value) return null;
        const error = {not_an_image: true};
        this.tryingToLoad.set(true);

        try {
            await this.getExtension(control.value);
            this.tryingToLoad.set(false);
            return null;
        } catch(e){
            console.log(error);
        }
        this.tryingToLoad.set(false);

        return error;
    }
}
