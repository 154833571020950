import {Pipe, PipeTransform } from "@angular/core";
import {MathUtils, uiLanguages} from "@tilby/tilby-ui-lib/utilities";

@Pipe({
    name: 'tilbyNumber',
    pure:false,
    standalone: true
})
export class TilbyNumberPipe implements PipeTransform {

    static numeric: {
        decimal_separator: string,
        thousands_separator: string,
    } = {
        decimal_separator: '.',
        thousands_separator: ',',
    };

    constructor() {}

    transform(value: number, fractionSize: number = 0): string {

        if(value === undefined || isNaN(value)) {
            value = 0;
        } else if(typeof value !== 'number') {
            value = Number(value);
        }

        const roundedNumber = MathUtils.round(value).toFixed(fractionSize);
        const separatedNumber = roundedNumber.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1###');
        const withDecimalSeparatorNumber = separatedNumber.replace('.', TilbyNumberPipe.numeric.decimal_separator || '.');
        const valueStr = withDecimalSeparatorNumber.replace(/###/g, TilbyNumberPipe.numeric.thousands_separator || '');

        return valueStr;
    }

    setup(language: string | undefined){
        // SEPARATOR
        const langData = language ? uiLanguages[language] : undefined;
        const { decimal_separator = ".", thousands_separator = "," } = langData ?? { decimal_separator: ".", thousands_separator: "," };
        const separatorsData = { decimal_separator, thousands_separator };
        TilbyNumberPipe.numeric = {...TilbyNumberPipe.numeric, decimal_separator: separatorsData.decimal_separator, thousands_separator: separatorsData.thousands_separator };
    }

}
