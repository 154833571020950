import { Directive, HostBinding, Input } from '@angular/core';
interface MyStyle {
    display: 'flex';
    flexDirection: 'row' | 'column';
    justifyContent: 'space-between';
    alignItems: string;
    flexWrap: 'wrap'|'nowrap';
    gap: string;
    flex: string;
    width: string;
    backgroundColor: string;
    margin: string;
}
@Directive({
    selector: '[sass]',
    standalone: true
})
export class SassDirective {
    @Input() sass?: Partial<MyStyle>;

    @HostBinding('style.display')
    get display() {
        return this.sass?.display || 'flex';
    }
    @HostBinding('style.flexDirection')
    get flexDirection() {
        return this.sass?.flexDirection || 'row';
    }
    @HostBinding('style.justifyContent')
    get justifyContent() {
        return this.sass?.justifyContent || 'space-between';
    }
    @HostBinding('style.alignItems')
    get alignItems() {
        return this.sass?.alignItems || 'center';
    }
    @HostBinding('style.flexWrap')
    get flexWrap() {
        return this.sass?.flexWrap || 'wrap';
    }
    @HostBinding('style.width')
    get width() {
        return this.sass?.width || 'inherit';
    }
    @HostBinding('style.backgroundColor')
    get backgroundColor() {
        return this.sass?.backgroundColor || 'inherit';
    }
    @HostBinding('style.flex')
    get flex() {
        return this.sass?.flex || '1 1 auto';
    }
    @HostBinding('style.gap')
    get gap() {
        return this.sass?.gap || '0 1.5rem';
    }
    @HostBinding('style.margin')
    get margin() {
        return this.sass?.margin || 'inherit';
    }

    constructor() {}
}
