import {Directive, ElementRef, EventEmitter, inject, Input, OnDestroy, Output} from '@angular/core';
import {
    delay,
    EMPTY,
    filter,
    fromEvent,
    iif,
    map,
    mergeMap,
    Observable,
    of,
    OperatorFunction,
    race,
    Subject,
    switchMap,
    takeUntil,
    tap,
    throttleTime,
    timer,
    withLatestFrom,
    zip
} from "rxjs";
import {GestureKeyValue, OutputGesture, TIME_MIN_VARIATION, TIME_MIN_VARIATION_BEFORE_TOUCHMOVE_EMIT, X_MIN_VARIATION, XY_MIN_SCROLL_VARIATION, Y_MIN_VARIATION} from "./tilby-gestures.model";

@Directive({
    selector: '[tilbyGestures]',
    standalone: true
})
export class TilbyGesturesDirective implements OutputGesture, OnDestroy {
    private readonly onDestroy$ = new Subject<void>();

    @Input() longPressTrigger = 600; //
    @Output() swipeLeft = new EventEmitter<number>();
    @Output() swipeRight = new EventEmitter<number>();
    @Output() longPress = new EventEmitter<number>();
    @Output() scrollDown = new EventEmitter<number>();
    @Output() scrollUp = new EventEmitter<number>();

    private elementRef = inject(ElementRef<HTMLElement>);
    touchStart = fromEvent<TouchEvent>(this.elementRef.nativeElement, 'touchstart', {passive: true});
    touchMove = fromEvent<TouchEvent>(this.elementRef.nativeElement, 'touchmove', {passive: true});
    touchEnd = fromEvent<TouchEvent>(this.elementRef.nativeElement, 'touchend', {passive: true});
    clickStart = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mousedown', {passive: true});
    mouseMove = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mousemove', {passive: true});
    mouseLeave = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mouseleave', {passive: true});
    clickEnd = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mouseup', {passive: true});
    contextMenu = fromEvent<TouchEvent>(this.elementRef.nativeElement, 'contextmenu', {passive: false}).pipe(tap(e => e.preventDefault()));

    elementPosition() {
        return this.elementRef.nativeElement.getBoundingClientRect();
    }

    // PIPELINE PER TUTTE LE GESTURE original$ può essere la prima riga di this.swipeOrPress o di this.clickOrLongClick mentre operatorToMAp è la seconda riga rispettivamente
    transformToGesture<A, T = A extends TouchEvent ? TouchEvent : MouseEvent>(original$: Observable<[DOMRect, T, T]>, operatorToMap: OperatorFunction<{ initialElPosition: DOMRect, start: T, end: T }, { moveX: number, moveY: number, timePress: number }>) {
        return original$.pipe(
            takeUntil(this.onDestroy$),
            throttleTime<[DOMRect, T, T]>(100),
            map(([initialElPosition, start, end]) => ({initialElPosition, start, end})),
            // tap(console.log),
            filter(({initialElPosition: {x: startX, y: startY}}) => Math.abs(startX - this.elementPosition().x) < XY_MIN_SCROLL_VARIATION && Math.abs(startY - this.elementPosition().y) < XY_MIN_SCROLL_VARIATION),
            operatorToMap,
            mergeMap<{ moveX: number, moveY: number, timePress: number }, Observable<GestureKeyValue>>(({moveX, moveY, timePress}) =>
                iif(() =>
                    Math.abs(moveX) > X_MIN_VARIATION,
                    iif(()=>Math.abs(moveY) < Math.abs(moveX)/4,of<GestureKeyValue>({key: moveX > 0
                            ? 'swipeLeft'
                            : 'swipeRight'
                        , value: moveX
                    }),EMPTY),
                    of<GestureKeyValue>(timePress > TIME_MIN_VARIATION && Math.abs(moveY) < Y_MIN_VARIATION
                        ? {key: 'longPress', value: timePress}
                        : {key: (moveY < Y_MIN_VARIATION)
                                ? 'scrollUp'
                                : 'scrollDown',
                            value: Math.abs(moveY)
                    }))),
        );

    }
    private longPressForTouch$(start:TouchEvent) {
        return of(this.getUsefulEntriesFromTouchStart(start)).pipe(
            delay(this.longPressTrigger),
            withLatestFrom(race(this.touchMove,timer(TIME_MIN_VARIATION_BEFORE_TOUCHMOVE_EMIT).pipe(switchMap(()=>of(this.getUsefulEntriesFromTouchStart(start)))))),
            mergeMap(([{changedTouches:{0: {clientX: startX=0, clientY: startY=0}}}, {changedTouches:{0: {clientX: moveX=0, clientY: moveY=0}}}]) =>
                iif(() => Math.abs(moveX - startX) < X_MIN_VARIATION && Math.abs(moveY - startY) < Y_MIN_VARIATION,
                    of(this.getUsefulEntriesFromTouchStart(start)),
                    this.touchEnd
                )
            ),
        );
    }
    private getUsefulEntriesFromTouchStart(start:TouchEvent){
        return {timeStamp: start.timeStamp + 1000, changedTouches: start.changedTouches}
    }
    swipeOrPress = this.transformToGesture(
        //$original
        this.touchStart.pipe(
            switchMap(start =>
                    zip(
                        of<DOMRect>(this.elementPosition()),
                        of(start),
                        race(
                           this.longPressForTouch$(start),
                            this.touchEnd)
                    )
                ),
            ),
        //operatorToMap
        map(({start: {changedTouches: {0: {clientX: startSwipeX, clientY: startSwipeY}}, timeStamp: startTime}, end: {changedTouches: {0: {clientX: endSwipeX, clientY: endSwipeY}}, timeStamp: endTime}}) => ({moveX: startSwipeX - endSwipeX, moveY: startSwipeY - endSwipeY, timePress: endTime - startTime})),
    );

    private getUsefulEntriesFromMouseStart(start:MouseEvent){
        return {timeStamp: start.timeStamp + 1000, clientX: start.clientX, clientY: start.clientY}
    }
    private longPressForMouse$(start:MouseEvent){
        return of(this.getUsefulEntriesFromMouseStart(start)).pipe(
            delay(this.longPressTrigger),
            withLatestFrom(race(this.mouseMove, timer(TIME_MIN_VARIATION_BEFORE_TOUCHMOVE_EMIT).pipe(switchMap(() => of(this.getUsefulEntriesFromMouseStart(start)))))),
            mergeMap(([{clientX: startX = 0, clientY: startY = 0}, {clientX: moveX = 0, clientY: moveY = 0}]) =>
                iif(() => Math.abs(moveX - startX) < X_MIN_VARIATION && Math.abs(moveY - startY) < Y_MIN_VARIATION,
                    of(this.getUsefulEntriesFromMouseStart(start)),
                    this.clickEnd
                )
            ),
        );
    }

    clickOrLongClick = this.transformToGesture(
        //$original
        this.clickStart.pipe(
            switchMap(start =>
                zip(
                    of<DOMRect>(this.elementPosition()),
                    of(start),
                    race(
                       this.longPressForMouse$(start),
                        this.mouseLeave,
                        this.clickEnd
                    )
                )
            )
        ),
        //operatorToMap
        map(({start: {clientX: startSwipeX, clientY: startSwipeY, timeStamp: startTime}, end: {clientX: endSwipeX, clientY: endSwipeY, timeStamp: endTime}}) => ({moveX: startSwipeX - endSwipeX, moveY: startSwipeY - endSwipeY, timePress: endTime - startTime})),
    );

    constructor() {
        this.contextMenu.subscribe();
        this.clickOrLongClick.subscribe(event => this.emitTheGesture(event));
        this.swipeOrPress.subscribe(event => this.emitTheGesture(event));
    }

    emitTheGesture(gesture: GestureKeyValue) {
        if (gesture.key) this[`${gesture.key}`].emit(gesture.value)
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
