import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {SalesItemsPriceChanges, SalesPriceChanges} from "tilby-models";

export type PriceChangesType = SalesItemsPriceChanges.TypeEnum | SalesPriceChanges.TypeEnum;

export type PriceChange = Pick<SalesItemsPriceChanges&SalesPriceChanges,'description'|'value'|'type'>;

export type PriceChangeDescriptionObj = Record<PriceChangesType,{label:string;printParams?:true}>;

@Pipe({
  name: 'tilbyPriceChange',
  standalone: true,
})
export class TilbyPriceChangePipe implements PipeTransform {
  private translateService= inject(TranslateService);

  transform(price_change:PriceChange&any, priceChangeDescriptionsObj:PriceChangeDescriptionObj): string {
    if (!price_change.description && (price_change.type in priceChangeDescriptionsObj)) {
      const {label,printParams} =priceChangeDescriptionsObj[price_change.type as PriceChangesType];
          return this.translateService.instant(label||'',printParams&&{value:price_change.value});
    } else {
      return (price_change.type?.split('_').pop() ==='perc') ? `${price_change.description} (${price_change.value}%)` : price_change.description;
    }
  }

}
