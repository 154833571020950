import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {Directive, ElementRef, EnvironmentInjector, inject, Input, Renderer2, runInInjectionContext} from '@angular/core';
import {subscribeInComponent} from "@tilby/tilby-ui-lib/utilities";
import {Observable} from "rxjs";

@Directive({
  selector: '[tilbyResizeVirtualScroll]',
  standalone: true
})
export class TilbyResizeVirtualScrollDirective {
    private renderer2=inject(Renderer2);
    private cdkVirtualScrollViewportRef:ElementRef<HTMLElement>=inject(ElementRef);
    private cdkVirtualScrollViewport:CdkVirtualScrollViewport=inject(CdkVirtualScrollViewport);
    private injector=inject(EnvironmentInjector);

    @Input()pixelToSubtract=0;
    @Input() resizeSplitView$!:Observable<number>;
    private _height=0;

    parentElement?:HTMLElement;
    resizeObserver?:ResizeObserver;
    ngOnInit(){
        this.listenResize();
    }
    ngOnDestroy(){
        this.resizeObserver?.disconnect();
    }
    private listenResize(){
        this.resizeObserver = new ResizeObserver((entries) => {
            this.cdkVirtualScrollViewport.checkViewportSize();
        });

        this.resizeObserver?.observe(this.cdkVirtualScrollViewportRef!.nativeElement);

    }
 /* constructor() {
      this.setVirtualHeight();
      setTimeout(()=> {
          this.parentElement=this.cdkVirtualScrollViewportRef?.nativeElement.parentElement!;
          this.resetView();
      });
      // CapacitorWrapper.keyboard.addListener?.('keyboardDidHide', () => {this.resetView();});
  }

  ngOnInit(){
      this.resetView();
      runInInjectionContext(this.injector,()=>{
          subscribeInComponent(this.resizeSplitView$,_=> this.resetView());
      });
  }

    private log(...arg:any[]){
      // console.debug('RESIZE_VIRTUAL_SCROLL_DIRECTIVE: ',...arg)
    }

  private resetView(){
      setTimeout(() => {
          this._height=(this.parentElement?.parentElement?.getBoundingClientRect()?.height||0)-this.pixelToSubtract;
          this.log(this.parentElement?.nodeName,this.parentElement?.getBoundingClientRect(),this.parentElement?.parentElement?.nodeName,this.parentElement?.parentElement?.getBoundingClientRect(),this.parentElement?.getBoundingClientRect()?.height, 'HEIGHT', this.parentElement?.nodeName,this._height);
          this.cdkVirtualScrollViewport?.checkViewportSize();
          this.setVirtualHeight()
      }, 100)
  }*/

  private setVirtualHeight (){
      if(this.cdkVirtualScrollViewport){
          this.renderer2.setStyle(this.cdkVirtualScrollViewportRef.nativeElement,'height',`${this._height}px`);
      }
  }

}
