// matchAllNumbers('ciao4x5x555') ----> ['4', '5', '555']
export const matchAllNumbers = (word:string)=>word.match(/\d+/g);

// matchAllWords('ciao4x5x555') ----> ['ciao', 'x', 'x']
export const matchAllWords = (word:string)=>word.match(/\D+/g);

// matchWordsAndNumbers('ciao4x5x555') ----> ['ciao', '4', 'x', '5', 'x', '555']
export const matchWordsAndNumbers = (word:string)=>word.match(/\d+|\D+/g);

export const containsAllUppercase = (str: string) => /[A-Z]/.test(str);

export const containsAllNumbers = (str: string) => /^[0-9]*$/.test(str);

export const containsSomeUppercase = (str: string) => /[A-Z]/.test(str);

export const containsSomeSpace = (str: string) => /\s/.test(str);
