import {Component, Input, inject} from '@angular/core';
import {GeneralInputButton} from "@tilby/tilby-ui-lib/models";
import {MatButtonModule} from "@angular/material/button";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {TilbyGesturesDirective} from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatMenuModule} from "@angular/material/menu";

@Component({
    selector: 'tilby-general-input-button',
    standalone: true,
    templateUrl: './tilby-general-input-button.component.html',
    imports: [
        MatButtonModule,
        NgIf,
        MatIconModule,
        TilbyGesturesDirective,
        TranslateModule,
        MatFormFieldModule,
        MatMenuModule,
        NgForOf,
        NgClass
    ],
    styleUrls: ['./tilby-general-input-button.component.scss']
})
export class TilbyGeneralInputButtonComponent {
    private translate=inject(TranslateService);

    @Input({required:true}) btn!: GeneralInputButton;
    @Input() i: number=0;

    //START - WORKAROUND TO TRIGGER LONGPRESS WITHOUT CLICK
    private clickEnable =true;
    protected enableClick() {
        setTimeout(()=>this.clickEnable=true);
    }
    protected longPress(time: number, btn: GeneralInputButton) {
        this.clickEnable=false;
        btn.longPress?.(time,btn.name);
    }
    protected click($event: MouseEvent, btn: GeneralInputButton) {
        if(this.clickEnable) btn?.click($event,btn.name);
    }
    //END - WORKAROUND TO TRIGGER LONGPRESS WITHOUT CLICK

    protected getValueTranslated(value: string) {
        return (typeof this.translate.instant(value)==='object')
            ? value
            : this.translate.instant(value);
    }
}
