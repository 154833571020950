import {
    Component,
    ElementRef,
    inject,
    Injector,
    runInInjectionContext,
    ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatError,
    MatFormFieldModule
} from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { subscribeInComponent } from "@tilby/tilby-ui-lib/utilities";
import { TilbyImageHandlerValidatorDirective } from "./tilby-image-handler-validator.directive";
import { CustomFormControl } from '../../utils';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HostControlDirective } from "../../directives";

// 100 MB
const MEGA = 1048576;
const MEDIA_MAX_SIZE = 500 * MEGA;

@Component({
    selector: 'tilby-image-handler',
    templateUrl: './tilby-image-handler.component.html',
    styleUrls: ['./tilby-image-handler.component.scss'],
    hostDirectives: [TilbyImageHandlerValidatorDirective,HostControlDirective],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatError,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        TranslateModule,
    ],
    providers: [{provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' }}]
})
export class TilbyImageHandlerComponent {
    private hcd = inject(HostControlDirective);

    private readonly injector = inject(Injector);
    private readonly imageValidatorDirective = inject(TilbyImageHandlerValidatorDirective);

    formControl!: CustomFormControl;
    @ViewChild('file_input') fileInputElem!: ElementRef;
    @ViewChild('text_input') textInputElem!: ElementRef;

    protected extension = this.imageValidatorDirective.mediaExtension;
    protected tryingToLoad = this.imageValidatorDirective.tryingToLoad;

    ngOnInit(){
        ({control:this.formControl} = this.hcd);
        this.subscriptions();
    }

    private subscriptions() {
        // PER USARE subscribeInComponent (che inject(DestroyRef)) ho bisogno di chiamare la runInInjectionContext se sono fuori dal costruttore
        // this.formControl è disponibile solo dall'onInit quindi devo farlo così
        // ALTERNATIVE, "unsubscribe manuale" oppure "takeUntil(this.onDestroy$)" oppure "OnDestroyService"
        runInInjectionContext(
            this.injector,
            () => {
                if(!this.formControl) return;
                subscribeInComponent(
                    this.formControl.valueChanges,
                    async _ => {
                        this.formControl?.markAsTouched();
                        this.formControl?.markAsDirty();
                    }
                );
            }
        );
    }

    fileFieldChange(files: FileList|null) {
        if(files && files.length > 0) {
            const fileHandle = files.item(0);
            if(fileHandle && (fileHandle?.size < MEDIA_MAX_SIZE)) {
                this.handleNewFile(fileHandle);
            } else {
                if(fileHandle) {
                    // file too big
                    this.formControl?.setErrors({maxSize: MEDIA_MAX_SIZE / MEGA});
                }
                return;
            }
        }
    }

    addNewImage() {
        this.formControl?.setErrors(null);
        this.fileInputElem.nativeElement.click();
        this.fileInputElem.nativeElement.value = null;
    }

    async handleNewFile(fileHandle: File) {
        this.tryingToLoad.set(true);
        await this.formControl?.customProps.customActions?.suffix?.callback(this.formControl!,fileHandle);
        this.textInputElem.nativeElement.blur();
    }

    removeImage() {
        this.formControl?.setValue(undefined);
    }

    linkError($event: ErrorEvent) {
        this.formControl.setErrors({invalid_link:true});
    }
}
