import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {GeneralInputButton} from "@tilby/tilby-ui-lib/models";

@Component({
  selector: 'tilby-info-panel',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatExpansionModule, TranslateModule, MatIconModule],
  templateUrl: './tilby-info-panel.component.html',
  styleUrls: ['./tilby-info-panel.component.scss']
})
export class TilbyInfoPanelComponent {
    protected panelOpenState= false;
    protected nomeDeviceInitials!: string;
    private _nomeDevice='nome_device';
    get nomeDevice() {
        return this._nomeDevice;
    }
    @Input() set nomeDevice(value:string){
        this._nomeDevice = value;
        this.nomeDeviceInitials = this.getNomeDeviceInitials(value);
    };
    @Input() nomeStore='nome_store';
    @Input() btns: GeneralInputButton[] = [];

    private getNomeDeviceInitials(nomeDevice: string) {
        return nomeDevice.split(' ').map((w, i, array) => array.length === 1 ? w.slice(0, 2) : w.charAt(0)).join('').slice(0, 5);
    }
}
