import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import {MatButtonModule} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";
import {BaseInputButton} from "@tilby/tilby-ui-lib/models";

@Component({
  selector: 'tilby-dialog-action-buttons',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, TranslateModule],
  templateUrl: './tilby-dialog-action-buttons.component.html',
  styleUrls: ['./tilby-dialog-action-buttons.component.scss'],
  hostDirectives:[MatDialogActions],
  host:{
    class:'tw-px-6'
  }
})
export class TilbyDialogActionButtonsComponent {
  @Input({required:true}) buttons: BaseInputButton[]=[];

}
