import {Pipe, PipeTransform } from "@angular/core";
import {currencies, MathUtils, uiLanguages} from "@tilby/tilby-ui-lib/utilities";

@Pipe({
    name: 'tilbyCurrency',
    standalone: true
})
export class TilbyCurrencyPipe implements PipeTransform {

    static currency: {
        decimal_separator: string,
        thousands_separator: string,
        code: string,
        decimal_digits: number,
        symbol_native: string,
        symbol_first?: boolean
    } = {
        decimal_separator: '.',
        thousands_separator: ',',
        code: "EUR",
        decimal_digits: 2,
        symbol_native: "€"
    };

    constructor() {}

    transform(amount: number, currencySymbol: string = TilbyCurrencyPipe.currency.symbol_native, fractionSize: number = TilbyCurrencyPipe.currency.decimal_digits): string {

        if(amount === undefined || isNaN(amount)) {
            amount = 0;
        } else if(typeof amount !== 'number') {
            amount = Number(amount);
        }

        const roundedNumber = MathUtils.round(amount).toFixed(fractionSize);
        const separatedNumber = roundedNumber.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1###');
        const withDecimalSeparatorNumber = separatedNumber.replace('.', TilbyCurrencyPipe.currency.decimal_separator || '.');
        const valueStr = withDecimalSeparatorNumber.replace(/###/g, TilbyCurrencyPipe.currency.thousands_separator || '');

        if(currencySymbol && currencySymbol.trim().length > 0) {
            return TilbyCurrencyPipe.currency.symbol_first ? `${currencySymbol} ${valueStr}` : `${valueStr} ${currencySymbol}`;
        }

        return valueStr;
    }

    setupCurrency(language: string | undefined, currency: string = "EUR"){
        // SEPARATOR
        const langData = language ? uiLanguages[language] : undefined;
        const { decimal_separator = ".", thousands_separator = "," } = langData ?? { decimal_separator: ".", thousands_separator: "," };
        const separatorsData = { decimal_separator, thousands_separator };
        TilbyCurrencyPipe.currency = {...TilbyCurrencyPipe.currency, decimal_separator: separatorsData.decimal_separator, thousands_separator: separatorsData.thousands_separator };

        // CURRENCY
        const _currency = currencies[currency];
        TilbyCurrencyPipe.currency = {...TilbyCurrencyPipe.currency, code: _currency.code, decimal_digits: _currency.decimal_digits, symbol_native: _currency.symbol_native, symbol_first: _currency.symbol_first };
    }

}
