import { Component, Input, inject } from "@angular/core";
import { ControlContainer, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { StartEnd } from "../../models";
import { CustomFormControl, CustomFormControlProps, CustomFormGroup } from "../../utils";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { AsyncPipe, CommonModule, NgClass, NgForOf, NgIf, NgStyle } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { AutocompleteFilteredPipe } from "../../pipes";

@Component({
    selector: 'tilby-text-input-autocomplete',
    templateUrl: './tilby-text-input-autocomplete.component.html',
    styleUrls: ['./tilby-text-input-autocomplete.component.scss'],
    standalone: true,
    imports: [CommonModule, MatInputModule, MatIconModule, MatButtonModule, ReactiveFormsModule, TranslateModule, MatAutocompleteModule, AutocompleteFilteredPipe],
})
export class TilbyTextInputAutocompleteComponent {
    private parentControl = inject(ControlContainer);

    @Input({required:true}) formControlLabel!: string | number;
    @Input() appearance: MatFormFieldAppearance = 'fill';
    @Input() hintAlign: StartEnd ='end';
    @Input() flex: number = 1;

    protected parentForm!:CustomFormGroup<any>;
    protected control!:CustomFormControl;
    protected customProps: CustomFormControlProps<any,any> = new CustomFormControlProps();

    ngOnInit(){
        this.parentForm=this.parentControl.control as CustomFormGroup<any>;
        this.control=this.parentForm?.controls[this.formControlLabel] as CustomFormControl;
        this.customProps=this.control.customProps;
    }
}