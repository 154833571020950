import {Component, ElementRef, EventEmitter, Inject, InjectionToken, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    BehaviorSubject,
    concat,
    defer,
    filter,
    fromEvent,
    map,
    Observable,
    repeat,
    startWith,
    Subject,
    switchMap,
    take,
    takeUntil,
    tap, throttleTime,
    timer,
    withLatestFrom
} from "rxjs";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

const TOP_POSITION = 0;
export const LOADER_SERVICE_TOKEN = new InjectionToken('LoaderService');
export interface ILoaderService {
    loaderEnabled$: BehaviorSubject<boolean>;
}

/**
 * tilby-pull-to-request require 2 important things to work correctly + 1 Scss option
 * 1.         {provide: LOADER_SERVICE_TOKEN,useClass:LoaderService}, In the root of the application
 * 2.         LoaderService implements ILoaderService has to show and hide loader with the loaderEnabled$ linked to requests
 * 3.         Scss is minimal because in the app the loader color has to be overridden with the themes colors
 *              .tilby-frame-primary{
 *                   background: $primary-color;
 *                   color: $primary-color-contrast;
 *              }
*/
@Component({
    selector: 'tilby-pull-to-request',
    standalone: true,
    imports: [CommonModule, MatProgressSpinnerModule, MatIconModule],
    templateUrl: './tilby-pull-to-request.component.html',
    styleUrls: ['./tilby-pull-to-request.component.scss']
})
export class TilbyPullToRequestComponent implements OnInit,OnDestroy {
    @Input() targetElementRef?: ElementRef<HTMLElement>;
    @Output() refresh = new EventEmitter<void>();
    private pullDistance = 100; // 引っ張る距離
    private touchstart$?: Observable<TouchEvent>;
    private touchend$?: Observable<TouchEvent>;
    private touchmove$?: Observable<TouchEvent>;
    private drag$?: Observable<number>;
    private position$?: Observable<number>;
    positionTranslate3d$?: Observable<string>;
    rotateTransform$?: Observable<string>;
    opacity$?: Observable<number>;
    pos=TOP_POSITION;
    isLoading$ ;
    onDestroy$=new Subject<void>();

    constructor(@Inject(LOADER_SERVICE_TOKEN) private loaderService: ILoaderService) {
        this.isLoading$ = loaderService.loaderEnabled$.asObservable();
    }

    private initObsAfterInput() {
        this.touchstart$ = fromEvent<TouchEvent>(this.targetElementRef?.nativeElement || document, 'touchstart');
        this.touchend$ = fromEvent<TouchEvent>(this.targetElementRef?.nativeElement || document, 'touchend');
        this.touchmove$ = fromEvent<TouchEvent>(this.targetElementRef?.nativeElement || document, 'touchmove');
        this.drag$ = this.touchstart$.pipe(
            throttleTime(500),
            withLatestFrom(this.isLoading$),
            filter(([start,isLoading])=>!isLoading),
            switchMap(([start]) => {
                // this.pos = TOP_POSITION;

                return concat(
                    this.touchmove$!.pipe(
                        map((move) => move.touches[0].pageY - start.touches[0].pageY),
                        filter(p=>p>0),
                        tap((p) => (this.pos = p<this.pullDistance?p:this.pullDistance)),
                        filter((p) => p < this.pullDistance),
                        takeUntil(this.touchend$!)
                    ),
                    defer(() => this.tweenObservable(this.pos, this.pos<this.pullDistance?0:this.pullDistance/2, 200))// 位置を戻す
                );
            }),
            repeat()
        );
        this.position$ = this.drag$.pipe(startWith(TOP_POSITION));
        this.positionTranslate3d$ = this.position$.pipe(
            map((p) => `translate3d(0, ${p - this.pullDistance/2}px, 0)`)
        );
        this.rotateTransform$ = this.position$.pipe(
            map((p) => `rotate(${(p / this.pullDistance) * 360}deg)`)
        );
        this.opacity$ = this.position$.pipe(
            map((p) => p / this.pullDistance)
        );
    }

    ngOnInit(): void {
        this.initObsAfterInput();
        this.isLoading$.pipe(takeUntil(this.onDestroy$),filter(isLoading=>!isLoading)).subscribe(()=>this.pos=TOP_POSITION);
        if (this.touchstart$ && this.touchend$) {
                this.touchstart$.pipe(
                    takeUntil(this.onDestroy$),
                    switchMap((start) =>
                        this.touchend$!.pipe(
                            map((x) => Number(x.changedTouches[0].pageY - start.touches[0].pageY)||0),
                            withLatestFrom(this.isLoading$),
                            filter(([p,isLoading])=>!isLoading),
                        )
                    ),
                    filter(([p]) => p >= this.pullDistance)
                )
                .subscribe(() => this.refresh.emit()
            );
        }
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    private tweenObservable(start: number, end: number, time: number) {
        const emissions = time / 10;
        const step = (start - end) / emissions;

        return timer(0, 10).pipe(
            map((x) => start - step * (x + 1)),
            take(emissions)
        );
    }

}
